import Vue from 'vue'
import VueI18n from "vue-i18n"

import el from '@/src/lang/el/index';
import en from '@/src/lang/en/index'

const messages = {
    el,
    en
}

Vue.use(VueI18n)


const i18n = new VueI18n({
    locale: localStorage.getItem('ui.lang') || 'el',
    messages
})
// VueI18n instance
export default i18n;
