<template>

  <div id="comments">

    <div class="container">
      <div class="row">
        <div v-for="comment in api.rows" :key="comment.id" class="col-12">

          <div class="bg-white p-2 comment-item mb-3">
            - {{ comment.comment }}
            <div class="user text-right">
              <span class="text-primary mr-2">{{ formatDate(comment.createdAt) }}</span>
              <v-gravatar v-if="comment.user" :email="comment.user.email" alt="user-image" class="rounded-circle mr-2"
                          default-img="mp" style="max-width:25px"
              />
              <strong>{{ comment.user.firstName }} {{ comment.user.lastName }}</strong>
            </div>

          </div>

        </div>
        <div v-if="api.rows.length ===0">{{ $t('cloud.noComments') }}</div>
      </div>

      <pagination-row :api="api" @fetchData="getData"></pagination-row>

      <div v-if="!commentSubmitted" class="comment-area mt-5">
        <b-form-textarea v-model="comment"
                         :placeholder="$t('cloud.comment')"></b-form-textarea>

        <div class="text-right">
          <b-button :disabled="isCommenting" class="mt-2" variant="primary" @click="sendComment">
            {{$t('chat.send')}}
          </b-button>
          <p class="text-muted mt-2" v-html="$t('cloud.commentDisclaimer')"/>
        </div>

      </div>
    </div>

  </div>
</template>
<style lang="scss" scoped>
.comment-item {
  border-radius: 12px;
}
</style>

<script>
import PaginatedDataMixin from "@src/mixins/PaginatedDataMixin";
import PaginationRow from "@components/global/PaginationRow";

export default {
  components: {PaginationRow},
  mixins: [PaginatedDataMixin],
  props: ['listingId'],
  data() {
    return {
      isCommenting: false,
      comment: '',
      commentSubmitted: false
    }
  },
  created() {
    this.api.url = '/cloud/listings/' + this.listingId + '/comments';
    this.getData();
  },
  methods: {
    sendComment() {
      if (!this.comment || this.comment === '') return;
      this.isCommenting = true;
      this.$axios.post('/cloud/listings/' + this.listingId + '/comments', {
        comment: this.comment
      }).then(() => {
        this.comment = '';
        this.commentSubmitted = true;
        this.$notify({
          group: 'athlisis-notifications',
          type: 'success',
          title: this.$t('success.title'),
          text: this.$t('success.text')
        });
        this.getData();
      }).catch(e => {
        this.$notify({
          group: 'athlisis-notifications',
          type: 'error',
          duration: 5000,
          title: this.$t('errors.title'),
          text: this.$t('errors.text')
        });
      }).finally(() => {
        this.isCommenting = false;
      });
    }
  }
}
</script>
