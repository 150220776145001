export default {

    title: 'Choose a date',
    subtitle: 'Choose a date to see the data',
    weekly: 'Weekly view',
    comments: {
        title: 'Notes - comments for client',
        subtitle: 'Type a comment for your client for the date: {date}'
    },
    deleteConnection: 'Remove connection with client',
    missingEmail: 'Please save your client\'s email.',
    photos: 'Photos',
    notRegisteredUser: {
        title: 'Your client is not registered in the application',
        subtitle: 'In order to proceed, your client needs to download and register in Athlisis App.',
        content: '<h2>Installation Instructions:</h2>\n' +
            '        <ol>\n' +
            '          <li>Download the app from <a ref="nofollow" href="https://play.google.com/store/apps/details?id=com.athlisis.athlisis" target="_blank">\n' +
            '            Google Play Store (click or search for Athlisis)</a> or' +
            '            <a ref="nofollow" href="https://apps.apple.com/us/app/athlisis/id1551491052#?platform=iphone" target="_blank">\n' +
            '              App Store (click or search for Athlisis)' +
            '            </a>.\n'+
            '          </li>\n' +
            '          <li>Register with the <strong>same e-mail</strong> as the one saved in the client (make sure you note the correct one).</li>\n' +
            '          <li>After registering, the client can connect to the application.</li>\n' +
            '          <li>You can now press "Send connection request" that will be shown on this page.</li>\n' +
            '          <li>The client has to accept the connection request, and now you can begin interacting with him.</li>\n' +
            '        </ol>'
    },
    muted: {
        title: 'You are not authorized to see this',
        content: 'Client has disabled your access to their calendar.'
    },
    request: {
        title: 'Request connection',
        subtitle: 'Your client is already registered in the app. You just have to send the connection request and he has to accept it.',
        cta: 'Send connection request'
    },
    pending: {
        title: 'Pending approval',
        subtitle: 'Your request for connection has been sent to the client and is pending approval.'
    },
    hour: 'Hour',
    commentPlaceholder: 'Add comment',
    goalPlaceholder: 'Insert a goal...',
    autoShareWarning: 'These goals are also shared in the mobile app.',
    noReport: 'No reports',
}