<template>
  <div id="kids-bmi">
    <h5>{{ $t('tools.cole.title') }}</h5>
    <span class="text-muted">{{ $t('tools.cole.subtitle') }}</span>

    <div class="row mt-2">
      <div class="col-md-6">
        <b-form-group :label="$t('tools.cole.gender')" label-for="edit-name">
          <b-form-select v-model="gender">
            <option value="M">{{ $t('tools.cole.boy') }}</option>
            <option value="F">{{ $t('tools.cole.girl') }}</option>
          </b-form-select>
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group :label="$t('tools.age')" label-for="edit-age">
          <b-input-group :append="$t('tools.append.age')">
            <b-form-input id="edit-age" v-model="age"
                          :placeholder="$t('tools.cole.placeholder.age')"
                          type="text"
                          @change="fixAge"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group :label="$t('tools.height')" label-for="edit-height">
          <b-input-group :append="$t('tools.append.height')">
            <b-form-input id="edit-height" v-model="height"
                          :placeholder="$t('tools.cole.placeholder.height')" type="text"
                          @input="height = replaceCommaWithDot(height)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group :label="$t('tools.weight')" label-for="edit-weight">
          <b-input-group :append="$t('tools.append.weight')">
            <b-form-input id="edit-weight" v-model="weight"
                          :placeholder="$t('tools.cole.placeholder.weight')" type="text"
                          @input="weight = replaceCommaWithDot(weight)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="col-md-12 text-right">
        <b-button variant="primary" @click="calculate">{{ $t('tools.cole.btn') }}</b-button>
      </div>
      <div v-if="result" class="col-md-12 text-center mt-3">
        <b-alert show variant="warning"><strong><h4>{{ result }}</h4></strong>
          {{$t('tools.cole.bmi.resultPrefix')}}: {{ bmi }}</b-alert>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  data() {
    return {
      height: null,
      weight: null,
      bmi: null,
      age: null,
      gender: 'M',
      result: null,
      results: [0,1,2,3,4].map(x => this.$t(`tools.cole.bmi.results.${x}`)),
      boy: {
        2: [20.09, 18.02, 14.12, 13.37],
        2.5: [19.8, 18.13, 13.94, 13.22],
        3: [19.57, 17.89, 13.79, 13.09],
        3.5: [19.39, 17.69, 13.64, 12.97],
        4: [19.29, 17.55, 13.52, 12.86],
        4.5: [19.26, 17.47, 13.41, 12.76],
        5: [19.30, 17.42, 13.31, 12.66],
        5.5: [19.47, 17.45, 13.22, 12.58],
        6: [19.78, 17.55, 13.15, 12.50],
        6.5: [20.23, 17.71, 13.10, 12.45],
        7: [20.63, 17.72, 13.08, 12.42],
        7.5: [21.09, 18.16, 13.09, 12.41],
        8: [21.60, 18.44, 11.11, 12.42],
        8.5: [22.17, 18.76, 13.17, 12.45],
        9: [22.77, 19.10, 13.24, 12.50],
        9.5: [23.39, 19.46, 13.34, 12.57],
        10: [24.00, 19.84, 13.45, 12.66],
        10.5: [24.57, 20.20, 13.58, 12.77],
        11: [25.10, 20.55, 13.72, 12.89],
        11.5: [25.58, 20.89, 13.87, 13.03],
        12: [26.02, 21.22, 14.05, 13.18],
        12.5: [26.43, 21.56, 14.25, 13.37],
        13: [26.84, 21.91, 14.48, 13.59],
        13.5: [27.25, 22.27, 14.74, 13.83],
        14: [27.63, 22.62, 15.01, 14.09],
        14.5: [27.98, 22.96, 15.28, 14.35],
        15: [28.30, 23.29, 15.55, 14.60],
        15.5: [28.60, 23.60, 15.82, 14.86],
        16: [28.88, 23.90, 16.08, 15.12],
        16.5: [29.14, 24.19, 16.34, 15.36],
        17: [29.41, 24.46, 16.58, 15.60],
        17.5: [29.70, 24.73, 16.80, 15.81],
        18: [30.00, 25.00, 17.00, 16.00]
      },
      girl: {
        2: [19.81, 18.02, 13.90, 13.24],
        2.5: [19.55, 17.76, 13.74, 13.10],
        3: [19.36, 17.56, 13.60, 12.98],
        3.5: [19.23, 17.40, 13.47, 12.86],
        4: [19.15, 17.28, 13.34, 12.73],
        4.5: [19.12, 17.19, 13.21, 12.61],
        5: [19.17, 17.15, 133.09, 12.50],
        5.5: [19.34, 17.20, 12.99, 12.40],
        6: [19.65, 17.34, 12.93, 12.32],
        6.5: [20.08, 17.53, 12.90, 12.28],
        7: [20.51, 17.75, 12.91, 12.26],
        7.5: [21.01, 18.03, 12.95, 12.27],
        8: [21.57, 18.35, 13.00, 12.31],
        8.5: [22.18, 18.69, 13.08, 12.37],
        9: [22.81, 19.07, 13.18, 12.44],
        9.5: [23.46, 19.45, 13.29, 12.53],
        10: [24.11, 19.86, 13.43, 12.64],
        10.5: [24.77, 20.29, 13.59, 12.78],
        11: [25.42, 20.74, 13.79, 12.95],
        11.5: [26.05, 21.20, 14.01, 13.15],
        12: [26.67, 21.68, 14.28, 13.39],
        12.5: [27.24, 22.14, 14.56, 13.65],
        13: [27.76, 22.58, 14.85, 13.92],
        13.5: [28.20, 22.98, 15.14, 14.20],
        14: [28.57, 23.34, 15.43, 14.48],
        14.5: [28.87, 23.66, 15.72, 14.75],
        15: [29.11, 23.94, 15.98, 15.01],
        15.5: [29.29, 24.17, 16.22, 15.25],
        16: [29.43, 24.37, 16.44, 15.46],
        16.5: [29.56, 24.54, 16.62, 15.63],
        17: [29.69, 24.70, 16.77, 15.78],
        17.5: [29.84, 24.85, 16.89, 15.90],
        18: [30.00, 25.00, 17.00, 16.00]
      }
    }
  },
  methods: {
    fixAge() {
      this.age = this.replaceCommaWithDot(this.age);
      this.age = Math.round(this.age * 2) / 2; // rounds up or down to closest .5
      if (this.age < 2) this.age = 2
      if (this.age > 18) this.age = 18
    },
    calculate() {

      this.bmi = parseFloat((parseFloat(this.weight) / ((parseFloat(this.height) / 100.0) ** 2)).toFixed(1));

      // this.result = this.bmi;
      if (this.gender === 'M') {
        if (this.bmi >= this.boy[this.age][0]) {
          this.result = this.results[0];
        } else if (this.bmi >= this.boy[this.age][1]) {
          this.result = this.results[1];
        } else if (this.bmi < this.boy[this.age][1] && this.bmi > this.boy[this.age][2]) {
          this.result = this.results[2];
        } else if (this.bmi <= this.boy[this.age][2] && this.bmi > this.boy[this.age][3]) {
          this.result = this.results[3];
        } else {
          this.result = this.results[4];
        }
      } else {
        if (this.bmi >= this.girl[this.age][0]) {
          this.result = this.results[0];
        } else if (this.bmi >= this.girl[this.age][1]) {
          this.result = this.results[1];
        } else if (this.bmi < this.girl[this.age][1] && this.bmi > this.girl[this.age][2]) {
          this.result = this.results[2];
        } else if (this.bmi <= this.girl[this.age][2] && this.bmi > this.girl[this.age][3]) {
          this.result = this.results[3];
        } else {
          this.result = this.results[4];
        }
      }


    }
  }
}

</script>
