export default {
    betaWarning: 'Αυτόματη δημιουργία διατροφικού πλάνου -\n' +
        '        Δοκιμαστική λειτουργία.',
    days: 'Ποιες ημέρες θέλετε να παραχθούν; (Θα διαγραφούν τα περιεχόμενα αυτών των ημερών)',
    enableAll: 'Ενεργοποίηση όλων',
    disableAll: 'Απενεργοποίηση όλων',
    calories: 'Πόσες θερμίδες;',
    sameDinner: 'Ίδιο μεσημεριανό/βραδινό;',
    sameDinner2: 'επιλέγει πάντα το μεσημεριανό ως κατηγορία προτύπου',
    calorieDistribution: 'Πώς θέλετε να καταμεριθούν ποσοστιαία οι θερμίδες; (γράψτε 0 για να αγνοήσετε το γεύμα)',
    intro: '<p>\n' +
        '        Η αυτόματη δημιουργία διατροφικού πλάνου λειτουργεί με βάση τα δικά σας πρότυπα γεύματα. Για να έχετε καλύτερη\n' +
        '        εμπειρία χρήσης, θα χρειαστεί να φτιάξετε\n' +
        '        σωστά και ισορροπημένα πρότυπα ώστε το τελικό διαιτολόγιο να έχει σωστές συνθέσεις τόσο σε συνδυασμούς τροφίμων\n' +
        '        όσο και σε κατανομή μακροθρεπτικών συστατικών.\n' +
        '      </p>\n' +
        '      <p>\n' +
        '        Η ακρίβεια των ποσοτήτων καθορίζεται ανά 10 γραμμάρια σε τρόφιμα που έχουν μονάδα μέτρησης τα γραμμάρια και ανά\n' +
        '        μισό (τεμάχιο, κουταλάκι, φλυτζάνι κ.λπ.) για τις υπόλοιπες μονάδες μέτρησης.\n' +
        '      </p>\n' +
        '      <p>\n' +
        '        Το σύστημα δημιουργήθηκε για να σας βοηθήσει να κάνετε γρηγορότερα τη δουλειά σας και όχι να σας αντικαταστήσει.\n' +
        '        <strong>Παρακαλούμε να δώσετε προσοχή στο διαιτολόγιο και τις επιλογές του πριν το δώσετε σε κάποιον\n' +
        '          διαιτώμενο.</strong>\n' +
        '\n' +
        '      </p>\n' +
        '      <p>\n' +
        '        <strong>Εάν δεν έχετε πρότυπα γεύματα, δεν μπορεί να λειτουργήσει η αυτόματη παραγωγή διαιτολογίων.</strong>\n' +
        '      </p>',
}