<template>

  <div id="educational-component" class="text-center">
    <img src="@assets/images/undraw-empty.svg" class="img-elements-not-found" alt="" height="250"/>
    <div class="text-center" v-html="$t(isOfflineVersion ? 'educational.offlineVersionContent' : 'educational.content')" />
  </div>

</template>

<script>
export default{

}
</script>
