export default{
    results: '     <h4>Αποτελέσματα εισαγωγής</h4>\n' +
        '      <p CLASS="mt-3">Η μαζική εισαγωγή των πελατών ολοκληρώθηκε επιτυχώς. </p>\n' +
        '      <P>Τα αποτελέσματα της εισαγωγής ήταν τα ακόλουθα:</P>\n' +
        '      <ul>\n' +
        '        <li>Συνολικοί πελάτες: {total}</li>\n' +
        '        <li>Πελάτες που προστέθηκαν: {imported}</li>\n' +
        '        <li>Πελάτες που υπήρχαν ήδη: {duplicates}</li>\n' +
        '        <li>Αποτυχία εισαγωγής: {failedToImport}</li>\n' +
        '      </ul>',
    content: '      <p>Με αυτή τη λειτουργία μπορείτε να εισάγετε μαζικά τους πελάτες σας ανεβάζοντας ένα CSV αρχείο.</p>\n' +
        '      <p>Η μορφή του αρχείου πρέπει να είναι συγκεκριμένη και ο διαχωρισμός των στοιχείων να είναι με <strong>;</strong>\n' +
        '      </p>\n' +
        '      <p>Τα πεδία πρέπει να βρίσκονται με την σειρά <strong>Όνομα, Επώνυμο, email, ημερομηνία γέννησης, φύλο, κινητό\n' +
        '        τηλέφωνο, κόστος συνεδρίας</strong>. Εάν δεν έχετε κάποιο πεδίο,\n' +
        '        εισάγετε κενή εγγραφή. <br/>Μην διαγράψετε την κεφαλίδα του αρχείου.</p>\n' +
        '      <p>\n' +
        '        <a\n' +
        '            download href="https://athlisis-new.s3.eu-north-1.amazonaws.com/athlisis-clients-import-template.csv"\n' +
        '            target="_blank"\n' +
        '        >\n' +
        '          Μπορείτε να κατεβάσετε ένα πρότυπο csv προς συμπλήρωση κάνοντας κλικ εδώ\n' +
        '        </a>\n' +
        '      </p>\n' +
        '\n' +
        '      <p>\n' +
        '        Στο φύλο, εάν ο πελάτης σας είναι άνδρας, γράψτε <strong>Α</strong> ενώ αν είναι γυναίκα γράψε\n' +
        '        <strong>Γ</strong>\n' +
        '      </p>\n' +
        '\n' +
        '      <p style="font-size:0.9em">Η λήψη ενδέχεται να μη λειτουργεί από Safari. Δοκιμάστε με Google Chrome ή κάποιον\n' +
        '        άλλον browser.</p>'
}