import axios from "axios";
import {getSavedState, saveState} from "@utils/util";

export const state = {
    _dietPlanStatistics: getSavedState('dietPlans.statistics'),
    lastUpdated: getSavedState('dietPlans.lastUpdated'),
    shoppingList: new Set(),
    recipesShoppingList: new Set(),
    showTooltips: getSavedState('ui.showTooltips'),
    findDislikedFoods: getSavedState('ui.findDislikedFoods')
};

export const getters = {};

export const mutations = {

    CACHE_STATISTICS(state, statistics){
        state._dietPlanStatistics = statistics;
    },

    CACHE_STATISTICS_TIME(state, time){
        state.lastUpdated = time;
    },

    CLEAR_SHOPPING_LISTS(state){
        state.shoppingList = [];
        state.recipesShoppingList = [];
    },

    UPDATE_SHOPPING_LISTS(state, mealFoods){
        state.shoppingList = [];
        state.recipesShoppingList = [];

        const uniqueFoods = {};

        for(let food of mealFoods){

            let name = food.name.trim() + '_' +food.measurementUnit;
            if(!uniqueFoods[name]){
                uniqueFoods[name] = {
                    ...food
                }
            }else{
                uniqueFoods[name].quantity += parseFloat(food.quantity)
            }
        }

        state.shoppingList.push(...Object.values(uniqueFoods).filter(x => x.category !== 5))
        state.recipesShoppingList.push(...Object.values(uniqueFoods).filter(x => x.category === 5))
    },
    UPDATE_SHOW_TOOLTIPS(state, value){
        saveState('ui.showTooltips', value);
        state.showTooltips = value;
    },

    UPDATE_SHOW_DISLIKED_FOODS(state, value){
        saveState('ui.findDislikedFoods', value);
        state.findDislikedFoods = value;
    }
};

export const actions = {

    cacheDpStatistics({ commit }){

        let statistics = {
            labels: [],
            dietPlans: {
                newCount: 0,
                lastMonthCount: 0,
                totalCount: 0,
                veganCount: 0,
                fastingCount: 0,
                carbohydrationCount: 0,
                maintenanceCount: 0,
                increaseCount: 0,
                decreaseCount: 0
            },

        };

        return axios.get(process.env.VUE_APP_API + `/dietPlan/statistics`,).then( result => {

            statistics.labels = result.data.monthDpCount.labels;
            statistics.dietPlans.newCount= result.data.monthDpCount.series;
            statistics.dietPlans.lastMonthCount = result.data.lastMonthDpCount.series;
            statistics.dietPlans.totalCount = result.data.totalCount;
            statistics.dietPlans.veganCount = result.data.veganCount;
            statistics.dietPlans.fastingCount = result.data.fastingCount;
            statistics.dietPlans.carbohydrationCount = result.data.carbohydrationCount;
            statistics.dietPlans.maintenanceCount = result.data.goalMaintenance;
            statistics.dietPlans.increaseCount = result.data.goalIncrease;
            statistics.dietPlans.decreaseCount = result.data.goalDecrease;
            commit('CACHE_STATISTICS', statistics);
            commit('CACHE_STATISTICS_TIME', new Date());
            saveState('dietPlans.statistics', statistics);
            saveState('dietPlans.lastUpdated', state.lastUpdated);
        });

    },


    refreshCacheStatistics({commit, dispatch}){

        if(daysBetween(new Date(), new Date(state.lastUpdated)) >= 1){
            dispatch('cacheDpStatistics');
        }
        commit('CACHE_STATISTICS', getSavedState('dietPlans.statistics'));
        commit('CACHE_STATISTICS_TIME', getSavedState('dietPlans.lastUpdated'))
    },

    reset({commit}){
        commit('CACHE_STATISTICS', null);
        commit('CACHE_STATISTICS_TIME', null);
    },

    updateShoppingList({commit, dispatch}, dietPlanFoods){
        let mealFoods = []
        for(let arr of dietPlanFoods.dietPlanMeals) {
            mealFoods = mealFoods.concat(arr.map(x => {
                return x.foods.map(x => {
                    return {
                        id: x.food.id,
                        category: x.food.category,
                        name: x.hasAltName ? x.altName : x.food.name,
                        measurementUnit: x.food.measurementUnit,
                        quantity: parseFloat(x.food.quantity)
                    }
                })
            }));
        }

        mealFoods = [].concat.apply([], mealFoods);

        commit('UPDATE_SHOPPING_LISTS', mealFoods);

    },

    clearShoppingLists({commit}){
        commit('CLEAR_SHOPPING_LISTS');
    },

    changeShowTooltipValue({commit}, value){
        commit('UPDATE_SHOW_TOOLTIPS', value);
    }
};

// ===
// Private helpers
// ===

function daysBetween(date1, date2) {

    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24;

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(date1 - date2);

    // Convert back to days and return
    return Math.round(differenceMs / ONE_DAY);

}
