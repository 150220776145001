
export default {
    optionalDisclaimer: 'Fields marked with * are required.',
    warning: 'Please submit all the required fields!',
    required: 'This field is required',
    fields: {
        measures: {
            weight: 'Weight',
            fat: 'Fat',
            height: 'Height',
            muscleMass: 'Muscle Mass',
            organsFat: 'Organs Fat',
            skeletalMass: 'Skeletal Muscle Mass',
            metabolismAge: 'Metabolism Age',
            boneMass: 'Bone Mass',
            fatFreeMass: 'Fat-free Mass',
            fatMass: 'Fat Mass'
        },
        name: 'Name',
        lastName: 'Surname',
        date: "Date",
        birthDate: 'Date of birth',
        appointmentDate: 'Appointment Date',
        gender: {
            title: 'Gender',
            choose: 'Select gender:',
            male: 'Male',
            female: 'Female'
        },
        sessionCost: 'Session cost',
        email: 'E-mail',
        tel: 'Phone number',
        phone: 'Phone number',
        mobile: 'Mobile number',
        zipCode: 'Zip Code',
        address: 'Address',
        city: 'City'
    },
    placeholders: {
        birthDate: 'day/month/year',
        measures: {
            height: 'The client\'s height in cm',
            weight:'The client\'s weight in kg',
            fat: 'The client\'s fat in percentage (e.g. 10%)'
        },
        athlete: {
            name: 'The client\'s name',
            lastName: 'The client\'s surname',
            notes: 'Notes about the client'
        }
    }
}