export default {
    append: {
        kgPerWeight: '/ kg σωματικού βάρους',
        gr: 'gr',
        g: 'g',
        age: 'έτη',
        height: 'cm',
        weight: 'kg',
        mg_dl: 'mg/dl',
        kcal: 'kcal'
    },
    age: 'Ηλικία',
    height:'Ύψος',
    weight: 'Βάρος',
    cole: {
        title: 'Χαρακτηρισμός παιδιών βάση BMI (Cole et al 2007)',
        subtitle: 'Χαρακτηρισμός παιδιών από 2 έως 18 ετών με βάση το BMI τους.',
        gender: 'Φύλο',
        boy: 'Αγόρι',
        girl: 'Κορίτσι',
        btn: 'Χαρακτηρισμός παιδιού',
        placeholder: {
            age: 'Σε ακρίβεια μισού έτους (2, 2.5, 3, 3.5)',
            height: 'Ύψος του παιδιού σε εκατοστά',
            weight: 'Βάρος του παιδιού σε κιλά (kg)',
        },
        bmi: {
            resultPrefix: 'Το BMI είναι',
            results: {
                0: 'Παχυσαρκία',
                1: 'Υπέρβαρο',
                2: 'Φυσιολογικό βάρος',
                3: 'Ελλιποβαρή',
                4: 'Πολύ ελλιποβαρή'
            }
        }
    },
    bmi: {
        menu: 'Δείκτης Μάζας Σώματος Ενηλίκων',
        title: 'Υπολογισμός Δείκτη Μάζας Σώματος',
        subtitle: 'Χαρακτηρισμός επιπέδου υγείας με βάση τον Δ.Μ.Σ.',
        placeholder:{
            height: 'Εισάγετε το ύψος σε εκατοστά',
            weight: 'Εισάγετε το βάρος σε κιλά (kg)',
        },
        btn: 'Υπολογισμός Δ.Μ.Σ.',
        result: {
            prefix: 'Το BMI είναι',
            0: 'Ελλιποβαρής',
            1: 'Φυσιολογικό Βάρος',
            2: 'Υπέρβαρος',
            3: 'Παχύσαρκος Ι',
            4: 'Παχύσαρκος ΙΙ',
            5: 'Νοσογόνος Παχυσαρκία'
        }
    },
    insulin: {
        menu: 'Πιθανότητα αντίστασης στην ινσουλίνη',
        triglycerides: 'Τριγλυκερίδια νηστείας',
        glucose: 'Γλυκόζη νηστείας',
        btn: 'Υπολογισμός πιθανότητας',
        low: 'υψηλή',
        high: 'χαμηλή',
        result: {
            prefix: 'Ο δείκτης TyG είναι ίσος με',
            title: 'Υπάρχει <strong><u>{result}</u></strong> πιθανότητα αντίστασης στην ινσουλίνη',
            nafld: 'Υπάρχει πιθανότητα ύπαρξης Λιπώδης Διήθησης (NAFLD)'
        }
    },
    fiber: {
        menu: 'Ημερήσια πρόσληψη διαιτητικών ινών',
        dailyIntake: 'Ημερήσια πρόσληψη ενέργειας',
        placeholder:{
            dailyIntake: 'Ημερήσια πρόσληψη ενέργειας σε kcal',
        },
        btn: 'Υπολογισμός',
        result: 'Συνίσταται πρόσληψη <u><strong>{result}{unit}</strong> διαιτητικών ινών</u> ανά ημέρα.'
    },
    gfr: {
        menu: 'Χρόνια Νεφρική Ανεπάρκεια',
        title: 'Χρόνια Νεφρική Ανεπάρκεια - GFR Cockcroft-Gault',
        subtitle: 'Υπολογισμός με την εξίσωση GFR Cockcroft-Gault.',
        kreatin: 'Κρεατινίνη Ορού',
        placeholder: {
            kreatin: 'Κρεατινίνη Ορού'
        },
        btn: 'Υπολογισμός',
        results: {
            0: 'Στάδιο 1 με φυσιολογικό ή υψηλό GFR',
            1: 'Στάδιο 2 Ήπια ΧΝΑ',
            2: 'Στάδιο 3Α Μέτρια ΧΝΑ',
            3: 'Στάδιο 3Β Μέτρια ΧΝΑ',
            4: 'Στάδιο 4 Σοβαρή ΧΝΑ',
            5: 'Στάδιο 5 Τελικό Στάδιο ΧΝΑ'
        }
    }
}