export default {

    title: 'Επιλογή ημερομηνίας',
    subtitle: 'Επιλέξτε την ημερομηνία για να δέιτε την καταγραφή',
    weekly: 'Προβολή εβδομάδας',
    comments: {
        title: 'Σημειώσεις - Σχόλια προς πελάτη',
        subtitle: 'Αφήστε ένα σχόλιο στον πελάτη σας για την καταγραφή του στις {date}'
    },
    deleteConnection: 'Διαγραφή διασύνδεσης με πελάτη',
    missingEmail: 'Παρακαλώ εισάγετε ένα e-mail για τον πελάτη σας.',
    photos: 'Φωτογραφίες',
    notRegisteredUser: {
        title: 'Ο πελάτης σας δεν έχει εγγραφεί στην εφαρμογή',
        subtitle: 'Είναι απαραίτητη προϋπόθεση ο πελάτης σας να δημιουργήσει έναν λογαριασμό στο Athlisis App',
        content: '<h2>Οδηγίες εγκατάστασης:</h2>\n' +
            '        <ol>\n' +
            '          <li>Λήψη της εφαρμογής athlisis από το <a ref="nofollow" href="https://play.google.com/store/apps/details?id=com.athlisis.athlisis" target="_blank">\n' +
            '            Google Play Store (κάντε κλικ ή πληκτρολογήστε athlisis στην αναζήτησή του)</a> ή\n' +
            '            <a ref="nofollow" href="https://apps.apple.com/us/app/athlisis/id1551491052#?platform=iphone" target="_blank">\n' +
            '              App Store (κάντε κλικ ή πληκτρολογήστε athlisis στην αναζήτησή του)\n' +
            '            </a>\n' +
            '            από τον πελάτη.\n' +
            '          </li>\n' +
            '          <li>Εγγραφή με το <strong>ίδιο e-mail</strong> που έχει δηλωθεί στην καρτέλα του πελάτη (να το τονίσετε).</li>\n' +
            '          <li>Αφότου έχει δημιουργηθεί ο λογαριασμός του πελάτη, μπορεί να συνδεθεί στην εφαρμογή.</li>\n' +
            '          <li>Εσείς, μπορείτε οποιαδήποτε στιγμη να πατήσετε "Αποστολή αίτησης διασύνδεσης με τον πελάτη" που εμφανίζεται σε αυτή τη σελίδα.</li>\n' +
            '          <li>Τέλος, αρκεί ο πελάτης να αποδεχτεί την αίτησή σας και θα μπορείτε να δείτε τις καταγραφές του.</li>\n' +
            '        </ol>'
    },
    muted: {
        title: 'Δεν σας έχει παραχωρηθεί πρόσβαση',
        content: 'Ο πελάτης έχει απενεργοποιήσει την πρόσβασή σας στο ημερολόγιο καταγραφής του.'
    },
    request: {
        title: 'Κάντε αίτηση για σύνδεση με τον πελάτη σας',
        subtitle: 'Εάν ο πελάτης σας έχει ήδη λογαριασμό στην εφαρμογή athlisis, είναι υποχρεωτικό να κάνετε σύνδεση με αυτόν και να την αποδεχτεί.',
        cta: 'Αποστολή αίτησης διασύνδεσης με τον πελάτη'
    },
    pending: {
        title: 'Αναμονή έγκρισης πελάτη',
        subtitle: 'Η αίτησή σας για πρόσβαση στο ημερολόγιο καταγραφής έχει σταλεί στον πελάτη.'
    },
    hour: 'Ώρα',
    commentPlaceholder: 'Προσθήκη σχολίου',
    goalPlaceholder: 'Πληκτρολογήστε τον στόχο...',
    autoShareWarning: 'Οι στόχοι αυτοί μοιράζονται αυτόματα στο mobile app του πελάτη σας.',
    noReport: 'Χωρίς καταγραφή',
}