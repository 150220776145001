export default {
    add: 'Create new',
    title: 'Print templates',
    subtitle: 'View and edit your print templates',
    limit: 'You can create up to 10 print templates',
    default: 'Default template',
    system: 'System template',
    name: 'Template name',
    defaultLabel: 'Default',
    markAsDefault: 'Mark as default',
    modalTitle: 'Are you sure you want to mark this as your default?',
    modalBody: 'This template will be the preselected one in the print window!',
    edit: {
        title: 'Edit template',
        subtitle: 'Edit your print template',
        headerColor: 'Header color',
        daysColor: 'Day color',
        tableStripes: 'Table stripes',
        tableBorders: 'Table borders',
        background: 'Background',
        text: 'Text',
        info: 'Information',
        meals: 'Meals',
        days: 'Days (or options)',
        foods: 'Foods',
        categoryColors: 'Color foods based on their category',
        stripes: {
            none: 'Without stripes',
            columns: 'Columns',
            rows: 'Rows',
            mix: 'Mixed'
        },
        daySize: 'Days size',
        foodSize: 'Foods size',
        letterSpacing: 'Letter spacing',
        mealSize: 'Meals size',
        orientation: 'Print orientation',
        landscape: 'Landscape',
        portrait: 'Portrait',
        settings: {
            title: 'General settings',
            template: 'Print template',
            showName: 'Show your name',
            showDate: 'Show date',
            showNotes: 'Show notes (on a new page)',
            showLogo: 'Show logo',
            reverseHeaders: 'Reverse days / meals',
            showPlanName: 'Show plan name',
            dietPlans: {
                title: 'Diet plan',
                onePerPage: 'Show one day per page',
                showQuantity: 'Show food quantities',
                showAnalysis: 'Show plan analysis',
                showExercises: 'Show weekly exercise',
            },
            shoppingList: {
                title: 'Shopping List',
                show: 'Print the shopping list',
                showRecipes: 'Print recipes in the plan',
                showNotForgetList: 'Print the "do not forget" list',
                showFoodQuantities: 'Show food quantities',
                mergeFoods: 'Merge foods (ignores edited names)'
            },
        },
        translation: {
            titles: 'Titles',
            name: 'Plan name',
            date: 'Date',
            dietitian: 'Dietitian',
            days: 'Day names',
            meals: 'Meal names',
            others: 'Other text',
            shoppingList: 'Shopping list',
            dontForget: 'Do not forget the ingredients for the recipes...',
            doNotForget: 'Do not forget...',
            energy: 'Energy',
            carbs: 'Carbohydrates',
            proteins: 'Proteins',
            fats: 'Fats',
            fresh: 'Fresh',
            amylouxes: 'Αμυλούχες',
            animal: 'Animal-based',
            complex: 'Σύνθετα',
            stats: 'Food Statistics'
        },
        tabs:{
            colors: 'Colors',
            table: 'Table',
            text: 'Text',
            settings: 'Settings',
            translation: 'Translation'
        }
    }



}