import progress from "./progress";
import home from "./home";
import athletes from "./athletes/athletes";
import athlete from "./athletes/athlete";
import measurements from "./athletes/measurements";
import exercise from "./athletes/exercise";
import questionnaire from "./athletes/questionnaire";
import app from "./athletes/app";
import files from "./athletes/files";
import profile from "./profile/profile";
import printTemplates from "./profile/printTemplates";
import questionnaires from "./profile/questionnaires";
import notes from "./dietplans/notes";
import recipes from "./dietplans/recipes";

export default{
    progress,
    home,
    athletes,
    athlete,
    measurements,
    exercise,
    questionnaire,
    app,
    files,
    profile,
    printTemplates,
    questionnaires,
    notes,
    recipes,
}