export default {
    title: 'Πρότυπα σημειώσεων',
    subtitle: 'Δείτε & επεξεργαστείτε τα πρότυπα σημειώσεών σας',
    add: 'Προσθήκη νέου',
    name: 'Όνομα προτύπου',
    searchPlaceholder: 'Αναζήτηση προτύπου...',
    loadMore: 'Φόρτωση περισσότερών...',
    edit: {
        pageTitle: 'Επεξεργασία σημειώσεων',
        title: 'Σημειώσεις διατροφικού πλάνου',
        subtitle: 'Πληκτρολογήστε σημειώσεις προς εκτύπωση με τα διατροφικά σας πλάνα!',
        backBtn: 'Επιστροφή στα πρότυπα',
        createNew: 'Δημιουργία νέου προτύπου',
    }
}