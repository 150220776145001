export default {

    reportText: 'Please use this form only for confirmed issues. ' +
        'If you have any questions, contact us through our <strong>live chat</strong> for a much quicker turnaround time.',
    problem: 'What is your problem (or idea) about?',
    problemPlaceholder: 'e.g. Copy Diet Plan',
    idea: 'Please explain your problem (or idea)',
    insertSth: 'Please select a problem',
    ideaPlaceholder: 'Please describe your problem in as much detail as possible for us to reproduce.',
    menu: {
        report: 'Report an issue',
        scienceLib: 'Scientific Library',
        faq: 'Frequently Asked Questions',
        terms: 'Terms & Conditions',
        training: 'Training',
        vote: 'Roadmap',
        changelog: 'Changelog',
        videos: 'Training videos'
    }
}