export default {
    choose: 'Επιλέξτε ένα χρήστη από τη λίστα για να ξεκινήσετε τη συνομιλία.',
    findAthlete: 'Εύρεση καρτέλας',
    noMore: 'Δεν υπάρχουν άλλα μηνύματα!',
    noResults: 'Δεν υπάρχουν μηνύματα!',
    typeHere: 'Πληκτρολογήστε εδώ...',
    send: 'Αποστολή',
    recent: 'Πρόσφατες συνομιλίες',
    searchPlaceholder: 'Αναζήτηση πελατών...',
    list: 'Λίστα πελατών'
}