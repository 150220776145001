export default {
    recipes: {
        metaTitle: 'Athlisis - Recipe',
        empty: {
            title: 'Recipe not found',
            subtitle: 'You have either typed a wrong hyperlink, or the recipe is not available anymore.'
        },
        recipe: 'Recipe'
    },
    couponUse: {
        title: 'Type your coupon to view transaction history.',
        code: 'Coupon code',
        password: 'Password',
        view: 'View transactions',
        back: 'Back to last screen',
        placeholder: {
            code: 'Your coupon code',
            password: 'Your password'
        }
    }
}