export default {
    add: 'Προσθήκη νέου',
    title: 'Ερωτηματολόγια',
    subtitle: 'Δείτε & επεξεργαστείτε τα ερωτηματολόγιά σας',
    limit: 'Μπορείτε να έχετε έως 5 ερωτηματολόγια',
    name: 'Όνομα προτύπου',

    edit: {
        title: 'Επεξεργασία ερωτηματολογίου',
        name: 'Όνομα ερωτηματολογίου',
        questionnaire: 'Ερωτηματολόγιο',
        questionPlaceholder: 'Συμπληρώστε την ερώτησή σας',
        question: 'Ερώτηση',
        answerType: 'Είδος απάντησης',
        add: 'Προσθήκη ερώτησης',
        update: 'Ενημέρωση ερώτησης',
        yourQuestion: 'Η ερώτησή σας',

        yesNo: 'Επιλογή (ναι/οχι)',
        smallText: 'Πεδίο κειμένου (μικρό)',
        largeText: 'Πεδίο κειμένου (μεγάλο)',
        radios: 'Επιλογή (προσαρμοσμένες)',
        multiRadios: 'Πολλαπλή επιλογή (προσαρμοσμένες)',
        scale_one_five: 'Κλίμακα (1-5)',
        scale_one_ten: 'Κλίμακα (1-10)',

        addOptions: 'Επιλογές ερώτησης',
        disclaimer: 'Προσοχή! Οποιαδήποτε αλλαγή έχετε υλοποιήσει θα εφαρμοστεί σε όλα τα υπάρχοντα ερωτηματολόγια. Αν αφαιρέσετε κάποια ερώτηση, θα χαθεί και από τις ήδη συμπληρωμένες ερωτήσεις!'


    }
}