export default {
    login: {
        title: 'Σύνδεση',
        metaTitle: 'Σύνδεση στο athlisis',
        subtitle: 'Παρακαλώ εισάγετε το e-mail σας και τον κωδικό σας για να συνδεθείτε στην εφαρμογή.',
        otpTooManyFails: 'Έχετε βάλει πάνω από 5 φόρες λάθος τον 4-ψήφιο κωδικό. Παρακαλώ δοκιμάστε μετά την λήξη του τωρινού κωδικού.',
        otpFail: 'Λάθος 4-ψήφιος κωδικός',
        loginBtn: 'Σύνδεση',
        loginEducational: 'Σύνδεση για φοιτητές',
        forgotPwdBtn: 'Ξεχάσατε τον κωδικό σας;',
        noAccountBtn: 'Δεν έχετε λογαριασμό;',
        registerBtn: 'Εγγραφή',
        email: 'E-mail',
        password: 'Κωδικός πρόσβασης',
        emailPlaceholder: 'Εισάγετε το e-mail σας',
        passwordPlaceholder: 'Εισάγετε τον κωδικό σας',
        otp: {
            title: 'Εισαγωγή OTP',
            text: 'Εισάγετε τον 4-ψήφιο κωδικό που έχει σταλεί στο e-mail σας.',
            remaining: 'Απομένουν',
            noCode: 'Δεν λάβατε κωδικό;',
            resend: 'εκ-νέου αποστολή κωδικού',
            code: '4-ψήφιος κωδικός',
            issue: 'Εάν αντιμετωπίζετε πρόβλημα επικοινωνήστε μαζί μας στο'
        },
        googleResetPassword: 'Δεν έχετε ορίσει κωδικό για τον λογαριασμό σας, επειδή κάνατε εγγραφή με google.',
        googleResetPasswordCta: 'Πατήστε εδώ για να ορίσετε τον κωδικό σας'
    },
    register: {
        title: 'Εγγραφή',
        metaTitle: 'Εγγραφείτε στο athlisis',
        created: 'Ο λογαριασμός σας δημιουργήθηκε επιτυχώς!',
        subtitle: 'Δεν έχετε λογαριασμό;<br> Δημιουργήστε έναν άμεσα και γίνετε μέλος του <strong>athlisis.</strong>',
        registerBtn: 'Εγγραφή',
        lastName: 'Επώνυμο',
        placeholder: {
            name: 'Εισάγετε το όνομά σας',
            lastName: 'Εισάγετε το επώνυμό σας',
            password: 'Εισάγετε έναν κωδικό πρόσβασης',
        },
        hasAccount: 'Έχετε ήδη λογαριασμό;',
        hasAccountBtn: 'Συνδεθείτε'
    },

    confirm: {
        metaTitle: 'Ενεργοποίηση λογαριασμού',
        metaDesc: 'Ενεργοποίηση λογαριασμού στο athlisis',
        title: 'Ο λογαριασμός σας δημιουργήθηκε επιτυχώς.',
        emailSent: 'Ένα e-mail έχει αποσταλλεί στην ηλεκτρονική διεύθυνση',
        emailInfo: 'Παρακαλώ ελέγξτε για ένα e-mail και πατήστε στο σύνδεσμο ενεργοποίησης για να ολοκληρώσετε την εγγραφή σας.',
        backToLogin: 'Επιστροφή στη σύνδεση'
    },
    forgot: {
        metaTitle: 'Επαναφορά κωδικού',
        metaDesc: 'Επαναφορά κωδικού πρόσβασης στο athlisis',
        success: 'Σας έχει σταλεί ένα e-mail επαναφοράς του κωδικού σας!',
        title: 'Εισάγετε την ηλεκτρονική σας διεύθυνση και θα σας στείλουμε ένα e-mail με οδηγίες επαναφοράς του κωδικού σας.',
        resetBtn: 'Επαναφορά κωδικού',
        hasAccount: 'Έχετε ήδη έναν λογαριασμό;',
        hasAccountBtn: 'Συνδεθείτε τώρα',
    },
    recover: {
        metaTitle: 'Επαναφορά κωδικού',
        metaDesc: 'Επαναφορά κωδικού στο athlisis',
        passwordNoMatch: 'Οι κωδικοί πρόσβασης δεν είναι ίδιοι',
        repeat: 'Επανάληψη κωδικού πρόσβασης',
        repeatPlaceholder: 'Επαναλάβετε τον κωδικό πρόσβασης',
        title: 'Επαναφορά κωδικού',
        subtitle: 'Εισάγετε έναν νέο κωδικό πρόσβασης για την πιστοποίησή σας στην εφαρμογή!',
        changeBtn: 'Αλλαγή κωδικού πρόσβασης',

    },
    mobileRecover: {
        title: 'Επαναφορά κωδικού',
        subtitle: 'Εισάγετε έναν νέο κωδικό πρόσβασης για την ταυτοποίησή σας στην εφαρμογή Athlisis.',
        success: 'Ο Κωδικός πρόσβασής σας άλλαξε επιτυχώς'
    },
    paused: {
        title: 'Ο λογαριασμός σας έχει απενεργοποιηθεί',
        subtitle: 'Ο λογαριασμός σας έχει απενεργοποιηθεί. Για περισσότερες πληροφορίες, επικοινωνήστε μαζί μας στο info@athlisis.com',
        button: 'Νέα δοκιμή',
        logout: 'Αποσύνδεση'
    }

}