export default {
    add: 'Προσθήκη νέου',
    title: 'Πρότυπα εκτύπωσης',
    subtitle: 'Δείτε & επεξεργαστείτε τα πρότυπα εκτύπωσής σας',
    limit: 'Μπορείτε να έχετε έως 10 πρότυπα εκτύπωσης',
    default: 'Προεπιλεγμένο πρότυπο',
    system: 'Πρότυπο συστήματος',
    name: 'Όνομα προτύπου',
    defaultLabel: 'Προεπιλογή',
    markAsDefault: 'Ορισμός ως προεπιλεγμένο',
    modalTitle: 'Θέλετε σίγουρα να το ορίσετε ως προεπιλεγμένο;',
    modalBody: 'Το πρότυπο αυτό θα είναι αρχικά επιλεγμένο στις εκτυπώσεις σας.',

    edit: {
        title: 'Επεξεργασία προτύπου',
        subtitle: 'Επεξεργαστείτε το πρότυπο της εκτύπωσής σας',
        headerColor: 'Χρώμα κεφαλίδας',
        daysColor: 'Χρώμα ημέρων',
        tableStripes: 'Ρίγες πίνακα',
        tableBorders: 'Περιγράμματα πίνακα',
        background: 'Background',
        text: 'Κείμενο',
        info: 'Πληροφορίες',
        meals: 'Γεύματα',
        days: 'Ημέρες (ή επιλογές)',
        foods: 'Τρόφιμα',
        categoryColors: 'Χρωματισμός τροφίμων με βάση την κατηγορία τους',
        stripes: {
            none: 'Χωρίς ρίγες',
            columns: 'Στήλες',
            rows: 'Γραμμές',
            mix: 'Σκακιέρα'
        },
        daySize: 'Μέγεθος ημερών',
        foodSize: 'Μέγεθος τροφίμων',
        letterSpacing: 'Αποστάση γραμμάτων',
        mealSize: 'Μέγεθος γευμάτων',
        orientation: 'Προσανατολισμός εκτύπωσης',
        landscape: 'Οριζόντια (landscape)',
        portrait: 'Κατακόρυφα (portrait)',
        settings: {
            title: 'Γενικές ρυθμίσεις',
            template: 'Πρότυπο εκτύπωσης',
            showName: 'Εμφάνιση του ονόματός σας',
            showDate: 'Εμφάνιση ημερομηνίας',
            showNotes: 'Εμφάνιση σημειώσεων (σε νέα σελίδα)',
            showLogo: 'Εμφάνιση Λογοτύπου',
            reverseHeaders: 'Αντιστροφή ημερών / γευμάτων',
            showPlanName: 'Εμφάνιση ονόματος πλάνου',
            dietPlans: {
                title: 'Διατροφικό πλάνο',
                onePerPage: 'Εμφάνιση μιας ημέρας ανά σελίδα',
                showQuantity: 'Εμφάνιση ποσοτήτων τροφίμων',
                showAnalysis: 'Εμφάνιση της ανάλυσης του πλάνου',
                showExercises: 'Εμφάνιση της εβδομαδιαίας άσκησης',
            },
            shoppingList: {
                title: 'Λίστα για τα ψώνια',
                show: 'Εκτύπωση λίστας τροφίμων για ψώνια;',
                showRecipes: 'Εκτύπωση σύνθετων τροφίμων;',
                showNotForgetList: 'Εκτύπωση λίστας "μην ξεχάσω...";',
                showFoodQuantities: 'Προβολή ποσότητας τροφίμων;',
                mergeFoods: 'Συγχώνευση τροφίμων (αγνόηση επεξεργασμένων ονομάτων);'
            },
        },
        translation: {
            titles: 'Τίτλοι',
            name: 'Όνομα πλάνου',
            date: 'Ημερομηνία',
            dietitian: 'Διατροφολόγος',
            days: 'Ονόματα ημερών',
            meals: 'Ονόματα γευμάτων',
            others: 'Άλλα κείμενα',
            shoppingList: 'Λίστα για ψώνια',
            dontForget: 'Μην ξεχάσετε επίσης τα υλικά των συνταγών...',
            doNotForget: 'Μην ξεχάσω...',
            energy: 'Ενέργεια',
            carbs: 'Υδατάνθρακες',
            proteins: 'Πρωτεϊνες',
            fats: 'Λίπη',
            fresh: 'Φρέσκιες',
            amylouxes: 'Αμυλούχες',
            animal: 'Ζωϊκές',
            complex: 'Σύνθετα',
            stats: 'Στατιστικά Τροφίμων'
        },
        tabs:{
            colors: 'Χρώματα',
            table: 'Πίνακας',
            text: 'Κείμενο',
            settings: 'Ρυθμίσεις',
            translation: 'Μετάφραση'
        }
    }



}