export default {
    methods: {
        isValidAlternativeMeasure(mealFood){
            return mealFood.mU && this.getVariationQuantity(mealFood);
        },
        // gets alternative, if there, and default to grammars (id = 8 for grammars)
        getAlternativeMeasures(food){
            if (!food.measures.variations) return [];
            return [...food.measures.variations.map( x => ({
                value: x.mU,
                text: this.$t('measures.short.' + x.mU)
            } )), { value: null, text: this.$t('measures.short.8') } ]
        },

        // Gets the quantity for a variation, if set.
        getVariationQuantity(mealFood){
            if(mealFood.mU) return +(mealFood.food.measures?.variations?.find(x => x.mU === mealFood.mU)?.qt);
            return null;
        },

        // Returns the food quantity based on the mealFood's setup.
        getFoodQuantity(mealFood) {
            const variationQt = this.getVariationQuantity(mealFood);
            if(this.isValidAlternativeMeasure(mealFood)){
                // get ratio 1 to Y
                const ratio = variationQt / (+mealFood.food.baseQuantity);
                const quantity = (1 / ratio) * (+mealFood.quantity);
                return +(quantity.toFixed(2));
            }
            return +(mealFood.food.quantity.toFixed(2));
        },

        // Display the correct food unit based on the mealFood setup.
        formatMealFoodUnits(mealFood) {
            const food = mealFood.food;
            const quantity = this.getFoodQuantity(mealFood);
            const measurementUnit = this.isValidAlternativeMeasure(mealFood) ? mealFood.mU : food.measurementUnit;

            let print = Number.isInteger(quantity) ? quantity : quantity.toFixed(1);
            let key = print;

            // check if measurementUnit ID isn't grammars, if so then change key to greek letters instead of 0.5
            if (measurementUnit !== 8) {
                print = this.replaceFoodUnits(key);
            }
            let measure = this.foodMeasures.find(x => x.id === measurementUnit);
            return measure && measure.displayName ? print + ' ' + measure.displayName : print;
        },

        // Helps by aggregating stats for a meal into a day's stats
        // both for macro nutrients and micro nutrients.
        addToDayStats(dayStats, mealStats) {
            dayStats.calories += mealStats.calories;
            dayStats.carbs += mealStats.carbs;
            dayStats.proteins += mealStats.proteins;
            dayStats.fats += mealStats.fats;
            for (let nutrient of this.microNutrientFields) {
                if (!dayStats.microNutrients[nutrient]) dayStats.microNutrients[nutrient] = 0;
                dayStats.microNutrients[nutrient] += mealStats.microNutrients[nutrient];
            }
        },

        // Gets the food (if not passed) and calculates all the values.
        prepareMealFoodData(meal, food){
            meal.food = food || JSON.parse(JSON.stringify(this.foods.find(food => food.id === meal.foodId)));
            meal.food.baseQuantity = meal.food.quantity;
            meal.food.kcal *= meal.quantity;
            meal.food.carbohydrates *= meal.quantity;
            meal.food.proteins *= meal.quantity;
            meal.food.fats *= meal.quantity;
            meal.food.quantity *= meal.quantity;
            meal.category = meal.food.category;
        },

        // Aggregates stats for the food (cals, carbs, proteins, fats + microNutrients).
        aggregateStatsFromMealFoodData(stats, meal) {
            stats.calories += meal.food.kcal;
            stats.carbs += meal.food.carbohydrates;
            stats.proteins += meal.food.proteins;
            stats.fats += meal.food.fats;

            if (parseInt(meal.food.list) !== 0) {
                for (let nutrient of this.microNutrientFields) {
                    if (!meal.food.microNutrients[nutrient]) continue;
                    if (!stats.microNutrients[nutrient]) stats.microNutrients[nutrient] = 0;
                    stats.microNutrients[nutrient] += meal.quantity * parseFloat(meal.food.microNutrients[nutrient]);
                }
            }
        },

        // sort food list by ORDER first, ID second.
        orderFoods(i, j) {
            if (this.days[i][j].foods) this.days[i][j].foods.sort((a, b) => {
                const aId = a.id;
                const bId = b.id;
                const aOrder = a.order === null ? 999 : a.order;
                const bOrder = b.order === null ? 999 : b.order;
                if (aOrder === 999 && bOrder === 999) return aId - bId;
                return aOrder - bOrder;
            });
        }
    }
}