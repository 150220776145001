export default {
    menu: {
        dashboard: 'Πίνακας ελέγχου',
        users: 'Λίστα πελατών',
        transactions: 'Συναλλαγές',
        coupons: 'Κουπόνια προσφοράς',
        notifications: 'Ειδοποιήσεις',
        foods: 'Βάση δεδομένων Τροφίμων',
        library: 'Βιβλιοθήκη',
        reports: 'Αναφορές χρηστών'
    },
    dashboard: {
        customers: 'Εγγεγραμένοι πελάτες (με ή χωρίς συνδρομή)',
        active: 'Ενεργοί πελάτες (με συνδρομή)',
        inactive: 'Ανενεργοί πελάτες (με συνδρομή)',
        foods: 'Τρόφιμα',
        dps: 'Διατροφικά πλάνα',
        clientele: 'Πελάτες',
        viewUsers: 'Προβολή χρηστών',
        sales: 'Πωλήσεις',
        salesTitle: 'Πωλήσεις ανά έτος'
    },
    users: {
        title: 'Λίστα πελατών',
        subscription: 'Συνδρομή;',
        googleLogin: 'Google Login',
        subscriptionExpiry: 'Λήξη ετήσιας συνδρομής',
        date: 'Ημερομηνία δημιουργίας',
        actions: {
            activate: 'Ενεργοποίηση συνδρομής',
            extend: ' Επέκταση ετήσιας συνδρομής (1 έτος)',
            pause: 'Παύση λογαριασμού',
            resume: 'Επανενεργοποίηση λογαριασμού',
            changeMail: 'Αλλαγή e-mail',
            transactionHistory: 'Ιστορικό συναλλαγών χρήστη'
        },
        emailPlaceholder: 'Εισάγετε το νέο e-mail',
        changeMailBtn: 'Αλλαγή e-mail',
        activate: {
            title: 'Θέλετε σίγουρα να ενεργοποιήσετε τη συνδρομή;',
            body: 'Θα προστεθεί υποχρέωση ανανέωσης της συνδρομής μετά το 1 έτος',
            okText: 'Ενεργοποίηση',
            success: 'Ο χρήστης ενεγοποιήθηκε επιτυχώς'
        },
        extend: {
            title: 'Θέλετε σίγουρα να επεκτείνετε τη συνδρομή;',
            body: 'Θα προστεθεί 1 έτος στη συνδρομή του χρήστη',
            okText: 'Επέκταση',
            success: 'Η συνδρομή επεκτάθηκε επιτυχώς!'
        }

    },
    articles: {
        title: 'Λίστα άρθρων',
        add: 'Προσθήκη άρθρου',
        name: 'Τίτλος άρθρου',
        category: 'Επιλογή κατηγορίας',
    },
    coupons: {
        ofCoupon: 'Κουπονιού',
        title: 'Κουπόνια Προσφοράς / Συνεργατών',
        code: 'Κωδικός κουπονιού',
        type: {
            title: 'Τύπος έκπτωσης',
            sale: 'Τιμή πώλησης (π.χ. 1240€)',
            percentage: 'Έκπτωση (π.χ. -100€)',
        },
        price: 'Τιμή αγοράς',
        partnerCode: 'Κωδικός συνεργάτη',
        partnerCodePlaceholder: 'Κωδικός πρόσβασης συνεργάτη (συστήνεται η δημιουργία του)',
    },
    transactions:{
        title: 'Λίστα συναλλαγών (ολοκληρωμένες πληρωμές μέσω Stripe)',
        invoiceDetails: 'Στοιχεία Τιμολόγησης',
        email: 'E-mail πελάτη',
        amount: 'Ποσό',
        datePayment: 'Ημερομηνία πληρωμής',
        types: {
            title: 'Είδος πληρωμής',
            renew: 'Ανανέωση',
            purchase: 'Αγορά λογισμικού'
        },
        document: {
            title: 'Παραστατικό',
            invoice: 'Τιμολόγιο',
            receipt: 'Απόδειξη'
        }
    },
    reports: {
        title: 'Αναφορές χρηστών',
        subject: 'Θέμα',
    },
    notifications: {
        title: 'Ενημερώσεις συστήματος',
        icon: 'Εικονίδιο',
        add: 'Προσθήκη ειδοποίησης',
        fieldTitle: 'Τίτλος ειδοποίησης',
        content: 'Κείμενο ειδοποίησης',
        default: 'Προεπιλεγμένο',
    },
    foods: {
        list: 'Λίστα',
        title: 'Τρόφιμα συστήματος',
    }

}