
export default {
    tabs: {
        general: 'Γενικές σημειώσεις',
        progress: 'Προβολή προόδου μετρήσεων',
        body: 'Σωματομετρικές',
        dermatoptixes: 'Δερματοπτυχές',
        mezoures: 'Μεζούρες',
        vitamins: 'Βιταμίνες',
        bloodGeneral: 'Γενικές Αιματολογικές',
        biochemicals: 'Βιοχημικές',
        hormonological: 'Ορμονολογικές',
        extra: 'Extra (λιπομετρητές)'
    },
    settings: 'Ρυθμίσεις μετρήσεων',
    printFatMeter: 'Εκτύπωση Λιπομετρητή',
    print: 'Εκτύπωση',
    printMeasurements: 'Εκτύπωση Μετρήσεων',
    progress: 'Προβολή προόδου',
    massImport: 'Μαζική εισαγωγή μετρήσεων',
    add: 'Προσθήκη μετρήσεων',
    date: 'Ημερομηνία μέτρησης',
    value: 'Τιμή μέτρησης',
    general: {
        title: 'Γενικές σημειώσεις',
        subtitle: 'Πληκτρολογήστε σημειώσεις για τις μετρήσεις του πελάτη σας ώστε να μην τις ξεχάσετε!'
    },
    fatMeter: {
        intro: 'Με αυτή τη λειτουργία μπορείτε να εισάγετε λιπομέτρηση από τον λιπομετρητή σας (CSV αρχείο).',
        insert: 'Εισαγωγή μετρήσεων',
        closeWithoutSave: 'Κλείσιμο χωρίς αποθήκευση',
        invalidCsv: 'Το CSV που έχετε εισάγει είναι εσφαλμένο. Επιλέξατε τον σωστό λιπομετρητή;'
    },
    printModal: {
        tabs: {
            base: 'Βασικά στοιχεία',
            options: 'Επιλογές Εκτύπωσης',
        },
        showDiagrams: 'Προβολή διαγραμμάτων',
        showText: 'Προβολή κειμένου',
        progress: 'Πρόοδος πελάτη'
    },
    printFatMeterModal: {
        warning: '' +
            '    <p>Η πρώτη έκδοση της εκτύπωσής μας για αποτελέσματα λιπομετρητή λειτουργεί με την τελευταία μέτρηση\n' +
            '              που υπάρχει καταχωρημένη στον πελάτη σας.</p>\n' +
            '            <p>Μελλοντικά, θα μπορείτε να επιλέξετε και πολλές μετρήσεις (2η σελίδα με διαγράμματα προόδου), καθώς και\n' +
            '              να\n' +
            '              εμφανίσετε την εκτύπωση για παλαιότερη ημερομηνία.</p>\n' +
            '            <p>Εάν αντιμετωπίζετε κάποιο πρόβλημα,\n' +
            '              επικοινωνήστε μαζί μας στο info@athlisis.com. Θα εκτιμηθεί να μας κάνετε αναλυτική αναφορά και να\n' +
            '              συνοδεύεται από εικόνες/βίντεο του προβλήματός σας.</p>',
        missingHeight: 'Προσοχή! Δεν έχετε εισάγει το\n' +
            '            <strong>ύψος</strong> του πελάτη σας.',
        missingWeight: '  Προσοχή! Δεν έχετε εισάγει <strong>βάρος</strong> για τον πελάτη σας και η εκτύπωση δεν μπορεί να\n' +
            '            λειτουργήσει σωστά.',
        information: 'ΠΛΗΡΟΦΟΡΙΕΣ',
        results: 'ΑΠΟΤΕΛΕΣΜΑΤΑ ΜΕΤΡΗΣΗΣ',
        partialAnalysis: 'ΤΜΗΜΑΤΙΚΗ ΑΝΑΛΥΣΗ',
        composition: 'ΣΥΣΤΑΣΗ ΣΩΜΑΤΟΣ',
        clothes: 'Ρούχα',
        result: 'Αποτελέσματα',
        recommended: 'Επιθυμητά',
        normalRange: 'Φυσιολογικά όρια',
        muscles: 'Μύες',
        torso: 'ΚΟΡΜΟΣ'
    },
    progressCard:{
        title: 'Πρόοδος μετρήσεων',
        subtitle: 'Δείτε συγκεντρωτικά την πρόοδο των μετρήσεων του πελάτη σας',
        info: 'Επιλέξτε μετρήσεις (ενεργές εμφανίζονται όσες έχετε περασμένες μετρήσεις)'
    },
    settingsIntro: ' <p>\n' +
        '            Αλλάζοντας τις ρυθμίσες αυτές, θα σταματήσουν να εμφανίζονται οι μετρήσεις που έχετε από-επιλέξει.<br> Δεν\n' +
        '            θα εμφανίζονται ούτε στην μαζική πρόοδο των μετρήσεων,\n' +
        '            ούτε στη μαζική εισαγωγή.<br>\n' +
        '            <i>Σημείωση: Δεν θα χαθούν μετρήσεις που έχετε ήδη εισάγει!</i>\n' +
        '          </p>',
    single: {
        edit: 'Επεξεργασία μέτρησης',
        progressNoEditable: 'Αυτή η μέτρηση έχει περαστεί από το φύλλο παρακολούθησης και δεν μπορεί να επεξεργαστεί εδώ',
        choose: 'ΕΠΙΛΟΓΗ ΜΕΤΡΗΣΗΣ',
        history: 'Ιστορικό Μετρήσεων',
        diagram: 'Διάγραμμα μεταβολής',
        add: 'Προσθήκη μέτρησης',
        empty: 'Δεν υπάρχουν μετρήσεις',
        normalRange: 'Φυσιολογικό εύρος τιμών',
        notePlaceholder: 'Σημειώσεις σχετικά με τη μέτρηση'
    }

}