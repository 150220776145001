export default{
    meals: {
        settings_title: 'Edit meal order/names',
        options: 'Options for diet plan meals',
        add: 'Add extra meal',
        remove: {
            btn: 'Remove extra meal',
            modal_title: '(WARNING) Are you sure you want to remove the extra meal?',
            modal_body: 'WARNING! The latest added meal will be deleted. If you have added two extra meals, the second one will be removed.' +
                ' The foods as well as the notes will also be deleted from them. <strong>This action is non-recoverable.</strong>',
            delete_button: 'Delete meal',
            cancel: 'Cancel'
        },
        extra: 'Extra meal',
        0: 'Breakfast',
        1: 'Snack',
        2: 'Lunch',
        3: 'Snack',
        4: 'Dinner',
        5: 'Extra meal 1',
        6: 'Extra meal 2'
    }
}