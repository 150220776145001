export default {

    reportText: 'Παρακαλώ χρησιμοποιείστε αυτή την φόρμα για αναφορά επιβεβαιωμένων προβλημάτων.\n' +
        '          Εάν έχετε απορίες, επικοινωνήστε μαζί μας μέσω του <strong>live chat</strong> για την άμεση εξυπηρέτησή σας. ',

    problem: 'Τι αφορά το πρόβλημά σας; (ή η ιδέα σας)',
    problemPlaceholder: 'π.χ. Αντιγραφή διατροφικού πλάνου',
    idea: 'Περιγράψτε μας το πρόβλημά σας (ή την ιδέα σας)',
    insertSth: 'Παρακαλώ εισάγετε κάποιο ζήτημα',
    ideaPlaceholder: 'Περιγράψτε μας το πρόβλημά σας όσο πιο αναλυτικά γίνεται ώστε να μπορέσουμε να το λύσουμε/διορθώσουμε/προσθέσουμε!',
    menu: {
        report: 'Αναφορά προβλήματος',
        scienceLib: 'Επιστημονική βιβλιοθήκη',
        faq: 'Συχνές ερωτήσεις',
        terms: 'Όροι χρήσης',
        training: 'Training',
        vote: 'Ψηφοφορία ιδεών',
        changelog: 'Changelog',
        videos: 'Εκπαιδευτικά βίντεο'
    }
}