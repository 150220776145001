
export default {
    tabs: {
        general: 'General notes',
        progress: 'Show measurement progress',
        body: 'Body measurements',
        dermatoptixes: 'Δερματοπτυχές',
        mezoures: 'Μεζούρες',
        vitamins: 'Vitamins',
        bloodGeneral: 'General Hematological',
        biochemicals: 'Biochemical',
        hormonological: 'Hormonological',
        extra: 'Extra (fat meters)'
    },
    settings: 'Measurement settings',
    printFatMeter: 'Fat meter Print',
    print: 'Print',
    printMeasurements: 'Print measurements',
    progress: 'View progress',
    massImport: 'Mass measurement import',
    add: 'Add measurements',
    date: 'Date of measurement',
    value: 'Value',
    general: {
        title: 'General notes',
        subtitle: 'Type notes about your customer\'s measurements to remember on your next appointment!'
    },
    fatMeter: {
        intro: 'Using this, you can import your measurements from your fat-meter exports (CSV file).',
        insert: 'Import measurements',
        closeWithoutSave: 'Close without saving',
        invalidCsv: 'The CSV you have uploaded is invalid.'
    },
    printModal: {
        tabs: {
            base: 'Base information',
            options: 'Print options',
        },
        showDiagrams: 'Show diagrams',
        showText: 'Show text',
        progress: 'Client progress'
    },
    printFatMeterModal: {
        warning: '' +
            '    <p>Η πρώτη έκδοση της εκτύπωσής μας για αποτελέσματα λιπομετρητή λειτουργεί με την τελευταία μέτρηση\n' +
            '              που υπάρχει καταχωρημένη στον πελάτη σας.</p>\n' +
            '            <p>Μελλοντικά, θα μπορείτε να επιλέξετε και πολλές μετρήσεις (2η σελίδα με διαγράμματα προόδου), καθώς και\n' +
            '              να\n' +
            '              εμφανίσετε την εκτύπωση για παλαιότερη ημερομηνία.</p>\n' +
            '            <p>Εάν αντιμετωπίζετε κάποιο πρόβλημα,\n' +
            '              επικοινωνήστε μαζί μας στο info@athlisis.com. Θα εκτιμηθεί να μας κάνετε αναλυτική αναφορά και να\n' +
            '              συνοδεύεται από εικόνες/βίντεο του προβλήματός σας.</p>',
        missingHeight: 'Caution! You have not imported the <strong>height</strong> of your client.',
        missingWeight: 'Caution! You have not imported the <strong>weight</strong> of your client and results might be invalid',
        information: 'INFORMATION',
        results: 'MEASUREMENT RESULTS',
        partialAnalysis: 'DEPARTMENTAL ANALYSIS',
        composition: 'BODY COMPOSITION',
        clothes: 'Clothes',
        result: 'Results',
        recommended: 'Desired',
        normalRange: 'Normal limits',
        muscles: 'Muscles',
        torso: 'TRUNK'
    },
    progressCard:{
        title: 'Measurement progress',
        subtitle: 'See the aggregated progress of your client\'s measurements',
        info: 'Select measurements (you can only select the ones you have at least one measurement on)'
    },
    settingsIntro: ' <p>By changing these settings, you\'ll stop seeing the non-selected measurements (in all places applicable).' +
        ' <i>Note: Measurements will not be lost, only hidden!</i></p>',
    single: {
        edit: 'Edit measurement',
        progressNoEditable: 'This measurement was imported from the monitoring sheet and can\'t be edited here',
        choose: 'ΕΠΙΛΟΓΗ ΜΕΤΡΗΣΗΣ',
        history: 'Measurement history',
        diagram: 'Measurement diagram',
        add: 'Add measurement',
        empty: 'No measurements found',
        normalRange: 'Normal value range',
        notePlaceholder: 'Notes about this measurement'
    }

}