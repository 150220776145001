<template>
  <div>

    <div v-if="!isOnline || showBackOnline" class="online-status-wrapper">
      <b-alert :show="!isOnline" class="p-2 pr-4" variant="danger">{{ $t('isOffline') }}</b-alert>
      <b-alert :show="showBackOnline" class="p-2 pr-4" variant="success">{{ $t('backOnline') }}</b-alert>

    </div>
  </div>
</template>

<style lang="scss">
.online-status-wrapper {
  position: fixed;
  bottom: 15px;
  right: 40px;
  z-index: 9999;
}
</style>

<script>
import {VueOfflineMixin} from 'vue-offline'

export default {
  mixins: [VueOfflineMixin],

  mounted() {
    this.$on('online', () => {
      this.showBackOnline = true;
      this.backOnlineInterval = setInterval(() => {
        this.showBackOnline = false;
      }, 2500);
    })
  },

  data() {
    return {
      showBackOnline: false,
      backOnlineInterval: null
    }
  },
  beforeDestroy() {
    if (this.backOnlineInterval) {
      clearInterval(this.backOnlineInterval)
    }
  }
}
</script>
