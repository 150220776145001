export default {
    dashboard: 'Πίνακας ελέγχου',
    athletes: {
        title: 'Πελάτες',
        all: 'Όλοι οι πελάτες',
        statistics: 'Στατιστικά στοιχεία',
        new: 'Προσθήκη νέου πελάτη',
        quickAdd: 'Γρήγορη προσθήκη πελάτη'
    },
    foods: 'Τρόφιμα',
    dietPlans: {
        title: 'Διατροφικά πλάνα',
        all: 'Όλα τα πλάνα',
        templates: 'Πρότυπα γεύματα',
        statistics: 'Στατιστικά στοιχεία',
        printTemplates: 'Πρότυπα εκτύπωσης',
        noteTemplates: 'Πρότυπα σημειώσεων',
        recipes: 'Συνταγές'
    },
    scienceTools: 'Επιστημονικά Εργαλεία',
    searchResults: 'Αποτελέσματα αναζήτησης',
    aCloud: 'aCloud',
    markAsRead: 'Σήμανση ως αναγνωσμένα',
    unread: 'Μη αναγνωσμένο',
    account: 'Λογαριασμός',
    logout: 'Αποσύνδεση',
    news: {
        title: 'Ενημερώσεις',
        noNews: 'ΔΕΝ ΥΠΑΡΧΟΥΝ ΕΝΗΜΕΡΩΣΕΙΣ'
    }

}
