export default {
    supplements: {
        title: 'Συμπληρώματα',
        suggested: 'Προτεινόμενα συμπληρώματα',
        beforeWorkout: 'Πριν την προπόνηση',
        dosage: 'Δοσολογία',
        duringWorkout: 'Κατά τη διάρκεια της προπόνησης',
        afterWorkout: 'Μετά την προπόνηση'
    },
    sport: 'Άθλημα',
    duration: 'Διάρκεια',
    add: 'Προσθήκη αθλήματος / άσκησης',
    addSport: 'Προσθήκη αθλήματος',
    placeholder: 'Πληκτρολόγηση αθλήματος...',
    durationMinutes: 'Διάρκεια σε λεπτά',
    pleaseInsertSport: 'Παρακαλώ εισάγετε άθλημα',
    pleaseInsertSportDuration: 'Παρακαλώ εισάγετε τη διάρκεια του αθλήματος'

}