export default {
    supplements: {
        title: 'Supplements',
        suggested: 'Recommended supplements',
        beforeWorkout: 'Before training',
        dosage: 'Dosage',
        duringWorkout: 'During training',
        afterWorkout: 'After training'
    },
    sport: 'Sport',
    duration: 'Duration',
    add: 'Add a sport',
    addSport: 'Add sport',
    placeholder: 'Type a sport...',
    durationMinutes: 'Duration in minutes',
    pleaseInsertSport: 'Please select a sport',
    pleaseInsertSportDuration: 'Please insert the duration in minutes'

}