export default {
    purchase: 'Purchase Athlisis',
    error: 'Εάν πιστεύετε ότι έχει γίνει λάθος, επικοινωνήστε μαζί μας στο info@athlisis.com',
    contact: 'Επικοινωνήστε μαζί μας στο info@athlisis.com',
    title: 'Ευχαριστούμε για την εγγραφή σας στο athlisis!',
    subtitle: 'Ολοκληρώστε την παραγγελία σας για να χρησιμοποιήσετε την εφαρμογή!',
    completeOrderBtn: 'Ολοκλήρωση παραγγελίας',
    startingPrice: 'Αρχική τιμή',
    salePrice: 'Εκπτωτική τιμή',
    pay: 'Πληρωμή',
    total: 'Ολόκληρο το ποσό',
    half: '2 Δόσεις',
    document: 'Αποδεικτικό',
    invoice: 'Τιμολόγιο',
    receipt: 'Απόδειξη',
    finalizePayment: 'Ολοκλήρωση πληρωμής',
    coupon: {
        name: 'Κωδικός κουπονιού',
        added: 'Ο κωδικός κουπονιού ενεργοποιήθηκε επιτυχώς.',
    },
    placeholder: {
        occupation: 'Εισάγετε το επάγγελμά σας (τομέας δραστηριότητας της εταιρείας)',
        company: 'Εισάγετε την επωνυμία της εταιρείας σας',
        doy: 'Εισάγετε τον Α.Φ.Μ. σας',
        vat: 'Εισάγετε τη Δ.Ο.Υ. σας',
        phone: 'Εισάγετε το κινητό σας',
        address: 'Εισάγετε την διεύθυνση της εταιρείας σας'
    },
    secondDoseTooltip: '*Η εξόφληση της 2ης δόσης πρέπει να ολοκληρωθεί σε 90 ημέρες',
    invoiceIntro: 'Παρακαλώ εισάγετε τα στοιχεία τιμολόγησής σας για την έκδοση του τιμολογίου σας.<br>\n' +
        'Εάν είστε επαγγελματίας στην Κύπρο, μην ξεχάσετε να βάλετε το πρόθεμα (CY).',
    vat: 'Φ.Π.Α {amount}%',
    logout: 'Αποσύνδεση;',
    connectedAs: 'Συνδεδεμένος ως <strong>{firstName} {lastName}</strong> (email: <strong>{email}</strong>)',
    expired: 'Η συνδρομή σας έχει λήξει.',
    renew: 'Ανανεώστε την τώρα κάνοντας κλικ εδώ',
    willExpireSingle: 'Η συνδρομή σας λήγει σε 1 ημέρα',
    willExpirePlural: 'Η συνδρομή σας λήγει σε {days} ημέρες',

    transaction: {
        title: 'Πληρωμή συναλλαγής',
        subtitle: 'Πληρώστε με ασφάλεια μέσω του ασφαλούς συστήματος πληρωμών.',
        payUntil: 'Πληρωμή έως',
        cost: 'Κόστος συναλλαγής',
        payTx: 'Πληρωμή συναλλαγής',
        payTxText: 'Πληρώστε άμεσα με την πιστωτική / χρεωστική σας κάρτα μέσω του ασφαλούς συστήματος πληρωμών μας.',
        invoiceText: 'Για να συνεχίσετε την πληρωμή σας με τιμολόγιο, παρακαλώ συμπληρώστε τα υποχρεωτικά στοιχεία\n' +
            '                  τιμολόγησής σας (ΑΦΜ, διεύθυνση, κινητό τηλέφωνο, επωνυμία).',
        invoiceTextBtn: 'Πατήστε εδώ για να τα συμπληρώσετε.',
    }
}