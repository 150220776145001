import Vue from 'vue'
import VueRouter from 'vue-router'
// https://github.com/declandewet/vue-meta
import VueMeta from 'vue-meta'
// Adds a loading bar at the top during page loads.
// import NProgress from 'nprogress/nprogress'
import routes from './routes'
import store from '../state/store'
import axios from "axios";

Vue.use(VueRouter)
Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: 'page',
})

const router = new VueRouter({
  routes,
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
});

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {

  // Check if auth is required on this route
  // (including nested routes).
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);
  const adminRequired = routeTo.matched.some((route) => route.meta.adminRequired);

  // If auth isn't required for the route, just continue.
  if (!authRequired) return next();

  if(adminRequired){
    store.dispatch('auth/validateAdmin').then(response=>{
      next();

    }).catch(e => {
      next({path:'/'});
    });
  }
  // If auth is required and the user is logged in...
  if (store.getters['auth/loggedIn']) {
    // Validate the local user token...

    return store.dispatch('auth/validate').then((validUser) => {
      // Then continue if the token still represents a valid user,
      // otherwise redirect to login.

      validUser ? next() : redirectToLogin()
    })
  }

  // If auth is required and the user is NOT currently logged in,
  // redirect to login.
  redirectToLogin();

  function redirectToLogin() {

    if(routeTo.query.redirect_from_google_calendar){
      return next({name: 'google-calendar-redirect'});
    }
    // Pass the original route to the login component
    next({ name: 'login', query: { redirectFrom: routeTo.fullPath } })
  }
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args);
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }

  // If we reach this point, continue resolving the route.
  next()
});


export const axiosFailInterceptor = (res) => {
  if (res.response?.data?.code === 'ACCESS_PAUSED') {
    return router.push({name: 'auth-paused'});
  }

  if(res.response && res.response.status === 401 && res.response.data.code === "NO_TOKEN" &&
      !res.response.config.url.includes('mobile.athlisis.com')){
    store.dispatch('auth/logOut').then(()=> {

      // // if a customer clicks on google calendar, he receives 401 and is lead here!
      // if(router.currentRoute.query.redirect_from_google_calendar){
      //   return router.push({name: 'google-calendar-redirect'})
      // }
      // check if current route requires Auth. If yes, redirect to login!
      if(router.currentRoute.meta.authRequired) {
        return router.push({name: 'login'});
      }
    });
  }

  if(res.response && res.response.data.code === "NOT_PURCHASED"){
    store.dispatch('auth/notPurchasedState');
    return router.push({name:'purchase-athlisis'});
  }
  if(res.response && res.response.data && res.response.data.code === "EXPIRED"){
    return router.push({name: 'user-subscription'});
  }

  if(res.response && res.response.status === 401 && res.response.data.code === "TOKEN_EXPIRED"){
    return store.dispatch('auth/logOut');
  }

  return Promise.reject(res);
}

// intercept on token issues!
axios.interceptors.response.use(
    (res) =>  res,
    (res) => axiosFailInterceptor(res)
);

export default router
