export default {
    account: 'Account',
    activationFailed: 'Something went wrong when activating your e-mail!',
    sidebar: {
        dashboard: 'User profile',
        company: 'Company details',
        password: 'Password',
        todo: 'To-do list',
        tags: 'Tags',
        subscription: 'Subscription & renewal',
        printTemplates: 'Print templates',
        questionnaires: 'Questionnaires',
        serial: 'Serial no.'
    },
    profile: {
        nonActivatedEmail: 'You have not confirmed your e-mail.',
        resendActivation: 'Re-send confirmation e-mail',
        emailActivationError: 'Something went wrong with confirming your e-mail!',
        uploadLogo: {
            title: 'Logo',
            subtitle: 'Upload your logo (used in printing etc.)'
        },
        addRemoveImage: 'Add / remove image',
        user: {
            title: 'User details',
            subtitle: 'Edit your base information & social media',
            insertYour: 'Insert your {field}',
            insertYourShe: 'Insert your {field}',
            insertYourSheN: 'Insert your {field}',
            bio: 'Bio',
            social: {
                title: 'Social media',
                website: 'Website',
                facebook: 'Facebook',
                instagram: 'Instagram',
                twitter: 'Twitter',
                linkedin: 'LinkedIn'
            }
        }
    },
    imageUpload: {
        profileImage: 'Profile picture',
        hint: 'Drop an image or click here to select',
        onlyImg: 'Please insert an image',
        outOfSize: 'Image is larger than the size limit: ',
        lowestPx: 'Image is too small. Expected size: '
    },
    company: {
        title: 'Company details',
        subtitle: 'Insert your company\'s details',
        company: 'Company name',
        companyPlaceholder: 'Insert your company\'s name',
        occupation: 'Occupation',
        vat: 'VAT',
        doy: 'DOY (optional)'
    },
    changePassword: {
        title: 'Change password',
        subtitle: 'Change your current password',
        password: 'Current password',
        newPassword: 'New password',
        placeholders: {
            password: 'Insert your current password',
            repeatPassword: 'Repeat your new password.',
            newPassword: 'Insert your new password.',
        },
        passwordsNoMatch: 'Passwords don\'t match.',
        save: 'Change password'
    },
    tags: {
        athlete: 'Client tags',
        dietPlans: 'Diet plan tags',
        templates: 'Meal template tags',
        edit: 'Edit tag',
        name: 'Tag name'
    },
    subscriptions: {
        title: 'Subscription info',
        subtitle: 'Information about your subscription & renewal',
        secondDoseExpiry: 'You have {days} days to pay your 2nd half of the initial payment plan!',
        secondUnpaid: 'Your 2nd half payment period has expied.',
        renew: 'Click here to renew your subscription.',
        cost: 'Yearly renewal cost: 149,90€ + VAT',
        daysUntil: 'Days until next renewal:',
        remaining: 'There are {days} days remaining',
        pay: 'Pay',
        statuses: {
            COMPLETED: 'Completed',
            PENDING: 'Pending payment'
        },
        remainingLong: '<p>There are <strong>{days} days</strong> until the next renewal</p>'
    },
    history: {
        title: 'Transaction history',
        subtitle: 'View all your transactions',
        amount: 'Amount',
        doses: 'Instalments',
        due: 'Due date',
        pay: 'Pay',
        payNow: 'Pay now',
        status: 'Status',
        type: {
            title: 'Transaction type',
            PURCHASE: 'Software purchase',
            RENEW: 'Yearly renewal'
        }
    }

}