export default {
    questionnaire: 'Questionnaire',
    add: 'Create questionnaire',
    short: 'Short questionnaire',
    long: 'Normal questionnaire',
    personal: 'Custom questionnaires',
    shareBtn: 'Share questionnaire',
    previewBtn: 'Show completed answers',
    printBtn: 'Print',
    share: {
        title: 'Share this questionnaire for offline completion',
        active: 'Sharing is enabled. Your client can view and answer the enabled questionnaires',
        inactive: 'Sharing is disabled. Your client can\'t view or answer in any of the questionnaires.',
        whichQ: 'Which questionnaires do you want to share?',
        copy: 'Copy link',
        enable: 'Start sharing',
        shareShortQ: 'Share Short Questionnaire',
        shareLongQ: 'Share Normal Questionnaire',
        sharePersonalQ: 'Share Custom Questionnaires',
    },
    print: {
        title: '',
        printShortQ: 'Print Short Questionnaire',
        printLongQ: 'Print Normal Questionnaire',
        printPersonalQ: 'Custom Questionnaires'
    },
    answered: 'Show completed answers'
}