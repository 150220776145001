
import axios from 'axios';

/**
 * A Helper to get data from CMS using a non-authenticated instance of axios.
 */
export const getFromCms = async (url, params = {}) => {

    const instance = axios.create();

    return instance.get(url,{params, transformRequest: (data, headers) => {
            delete headers['x-dietitian-token'];
            delete headers['x-access-token'];
        }})
}