export default {
    recipes: {
        metaTitle: 'Athlisis - Συνταγή',
        empty: {
            title: 'Δεν βρέθηκε η συνταγή',
            subtitle: 'Έχετε εισάγει λάθος σύνδεσμο, ή η συνταγή αυτή δεν είναι πλέον διαθέσιμη.'
        },
        recipe: 'Συνταγή'
    },
    couponUse: {
        title: 'Πληκτρολογήστε το κουπόνι σας και τον κωδικό σας για να δείτε το ιστορικό συναλλαγών.',
        code: 'Κωδ. Κουπονιού',
        password: 'Κωδ. πρόσβασης',
        view: 'Προβολή συναλλαγών',
        back: 'Επιστροφή στην εισαγωγή κουπονιού',
        placeholder: {
            code: 'Ο κωδικός κουπονιού σας',
            password: 'Ο κωδ. πρόσβασής σας'
        }
    }
}