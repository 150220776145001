
export default {
    optionalDisclaimer: 'Τα πεδία με * είναι υποχρεωτικά',
    warning: 'Προσοχή! Συμπληρώστε όλα τα απαραίτητα πεδία!',
    required: 'Το πεδίο είναι απαραίτητο',
    fields: {
        measures: {
            weight: 'Βάρος',
            fat: 'Λίπος',
            height: 'Ύψος',
            muscleMass: 'Μυική Μάζα',
            organsFat: 'Σπλαχνικό λίπος',
            skeletalMass: 'Σκελετική Μυϊκή Μάζα',
            metabolismAge: 'Μεταβολική ηλικία',
            boneMass: 'Οστική Μάζα',
            fatFreeMass: 'Άλιπη Μάζα',
            fatMass: 'Λιπώδης Μάζα'
        },
        name: 'Όνομα',
        lastName: 'Επώνυμο',
        date: "Ημερομηνία",
        birthDate: 'Ημερομηνία Γέννησης',
        appointmentDate: 'Ημερομηνία Συνεδρίας',
        gender: {
            title: 'Φύλο',
            choose: 'Επιλέξτε φύλο:',
            male: 'Άρρεν',
            female: 'Θήλυ'
        },
        sessionCost: 'Κόστος συνεδρίας',
        email: 'E-mail',
        tel: 'Τηλέφωνο επικοινωνίας',
        phone: 'Σταθερό τηλέφωνο',
        mobile: 'Κινητό τηλέφωνο',
        zipCode: 'Ταχυδρομικός Κώδικας',
        address: 'Διεύθυνση',
        city: 'Πόλη'
    },
    placeholders: {
        birthDate: 'Ημέρα/μήνας/έτος',
        measures: {
            height: 'Εισάγετε το ύψος του πελάτη σε cm',
            weight:'Εισάγετε το βάρος του πελάτη σε kg',
            fat: 'Εισάγετε το λίπος του πελάτη (π.χ. 10%)'
        },
        athlete: {
            name: 'Εισάγετε το όνομα του πελάτη',
            lastName: 'Εισάγετε το επώνυμο του πελάτη',
            notes: 'Εισάγετε σημειώσεις για τον πελάτη'
        }
    }
}