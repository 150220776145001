export default {
    one: 'Τρόφιμο',
    add: 'Προσθήκη τροφίμου',
    noEditSystem: 'Δεν μπορείτε να επεξεργαστείτε τρόφιμα του συστήματος.',
    editingSystem: 'ΠΡΟΣΟΧΗ! Επεξεργάζεστε τρόφιμο συστήματος',
    name: 'Όνομα τροφίμου',
    category: 'Κατηγορία τροφίμου',
    chooseCategoryError: 'Επιλέξτε μια κατηγορία για το τρόφιμο',
    measure: 'Μονάδα μέτρησης',
    howManyGrams: 'Σε πόσα γραμμάρια αντιστοιχεί;',
    chooseType: 'Επιλογή τύπου τροφίμου',
    attributes: {
        choose: 'Επιλογή ιδιοτήτων',
        isVeganQ: 'Είναι vegan;',
        isFastingQ: 'Είναι νηστίσιμο;'
    },
    loading: 'Φορτώνει...',
    noFoods: 'Δεν υπάρχουν τρόφιμα.',
    noMoreFoods: 'Δεν υπάρχουν άλλα τρόφιμα',
    wrongQuantity: 'Παρακαλώ εισάγετε σωστή ποσότητα τροφίμου.',
    trixopoulouContent: 'Σύσταση συνταγής',
    noTrixopoulouContent: 'Δυστυχώς δεν βρέθηκε η σύσταση της συνταγής!',
    lists: {
        '0': 'Πίνακας athlisis',
        '1': 'Τριχοπούλου (μικροθρεπτικά)',
        '2': 'USDA Greece',
        '3': 'Συνταγές',
        '4': 'Τα τρόφιμά μου',
        '5': 'aCloud τρόφιμα',
        favourites: 'Αγαπημένα τρόφιμα'
    },
    categories: {
        fresh: 'Φρέσκιες',
        carb: 'Υδατανθρακούχες',
        fat: 'Λιπαρές',
        protein: 'Πρωτεϊνούχες',
        complex: 'Σύνθετες',
    },
    stats: {
        macros: 'Μακροθρεπτικά',
        fats: 'Λίπη',
        fresh: 'Φρέσκιες',
        amylouxes: 'Αμυλούχες',
        animal: 'Ζωϊκές',
        complex: 'Σύνθετα',
        drinks: 'Ποτά / Γενικά',
        stats: 'Στατιστικά Τροφίμων'
    },
    dbCategories: {
        uncategorized: 'Χωρίς κατηγορία',
        fresh: 'Φρέσκιες',
        amylouxes: 'Αμυλούχες',
        fats: 'Λίπη',
        animal: 'Ζωϊκές',
        complex: 'Σύνθετα τρόφιμα',
        general: 'Ποτά / Γενικά',

    },
    foodTemplates: 'Πρότυπα τροφίμων',
    freeTemplates: 'Ελεύθερα πρότυπα',
    create: 'Προσθήκη νέου τροφίμου',
    showQuantity: 'Εμφάνιση ποσοτήτων',
    hideQuantity: 'Απόκρυψη ποσοτήτων',
    avoidedFood: 'Τρόφιμα που αποφεύγει',
    likedFood: 'Τρόφιμα που του αρέσουν',
    shortQuestionnaire: 'Σύντομο ερωτηματολόγιο',
    longQuestionnaire: 'Γενικό ερωτηματολόγιο',
    dri: 'DRI',
    dayProgress: 'Πρόοδος ημέρας',
    mealProgress: 'Πρόοδος γεύματος',
    carbohydrationWarning: 'Τα στατιστικά παρακάτω έχουν επεξεργαστεί ώστε να δημιουργηθεί η υδατανθράκωση!',
    tomatoes: 'Ντοματίνια',
    bread: 'Παξιμάδια',
    oliveOil: 'Ελαιόλαδο',
    cheese: 'Γραβιέρα'
}