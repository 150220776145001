import axios from "axios";
import Vue from "vue";
import {getSavedState, saveState} from "@utils/util";

export const state = {

    notes: getSavedState('notes.notes'),
    lastUpdate: getSavedState('notes.lastUpdate')

};

export const mutations = {

    CACHE_NOTES(state, notes){
        state.notes = notes;
        saveState('notes.notes', notes);
    },

    ADD_NOTE(state, note){
        state.notes.push(note);
        saveState('notes.notes', state.notes);
    },

    REMOVE_NOTE(state, noteId){
        let index = state.notes.findIndex(x => x.id === noteId);
        state.notes.splice(index, 1);
        saveState('notes.notes', state.notes);
    },

    UPDATE_NOTE(state, newNote){
      for(let note of state.notes){
          if(note.id === newNote.id){
              note.name = newNote.name;
              note.content = newNote.content;
          }
      }
     saveState('notes.notes', state.notes);

    },
    CACHE_UPDATED_TIME(state, time){
        state.lastUpdate = time;
        saveState('notes.lastUpdate', time);
    }


};

export const actions = {

    async init({ state, dispatch }) {
        if(localStorage.getItem('auth.token') !== 'null') {
            dispatch('refreshCacheNotes');
        }
    },

    async cacheNotes({commit}){
        console.warn('fetching notes...');

        axios.get(process.env.VUE_APP_API + `/dietPlan/notes`).then(result => {
            commit('CACHE_NOTES', result.data);
            commit('CACHE_UPDATED_TIME', new Date());
        }).catch(e=>{
            console.error("Failed to cache diet plan notes.")
         });

    },

    async refreshCacheNotes({commit, dispatch}){
        if(!state.lastUpdate || state.lastUpdate === 'null' || timeBetween(new Date(), new Date(state.lastUpdate)) >= 1){
            return dispatch('cacheNotes');
        }
    },

     addNote({commit, dispatch}, note){
        return axios.post(`${process.env.VUE_APP_API}/dietPlan/notes`, note).then(res => {
            if(res){
                commit('ADD_NOTE', res.data);
            }
        }).catch(() => {});

    },

    updateNote({commit, dispatch}, note){
        return axios.put(`${process.env.VUE_APP_API}/dietPlan/notes/${note.id}`, note).then(res => {
            if(res){
                commit('UPDATE_NOTE', res.data);
                return Promise.resolve();
            }
        }).catch((err) => {
            return Promise.reject(err);
        });
    },

    removeNote({commit, dispatch}, noteId){
        return axios.delete(`${process.env.VUE_APP_API}/dietPlan/notes/${noteId}`).then(res => {
            commit('REMOVE_NOTE', noteId);
        }).catch(err => {
            console.log(err);
        })
    },

    reset({commit}){
        commit('CACHE_NOTES', null);
        commit('CACHE_UPDATED_TIME', null);
    }
};


function timeBetween(date1, date2) {

    // The number of milliseconds in 24 hours
    const HOURS_24 = 1000 * 60 * 60 *24;

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(date1 - date2);

    // Convert back to days and return
    return Math.round(differenceMs / HOURS_24);

}
