export default{
    title: 'Dashboard',
    welcome: 'Welcome to Athlisis',
    educational: 'Educational',
    welcomeText: 'The No1 application for dietitians in Greece!',
    confirmEmail: {
        title: 'You haven\'t confirmed your e-mail address.',
        cta: 'Re-send confirmation e-mail'
    },
    news: {
        title: 'Latest news',
        subtitle: 'Read our latest news in our blog',
        cta: 'Read more'
    },

    birthdays: {
        title: 'Clients who have their birthday this month',
        subtitle: 'Don\'t forget to send them your wishes!',
        noResults: 'There are no clients with birthday this month.',
        phone: 'Phone:',
        cta: 'View',
    },
    educationalCta: `
            <p>
              Ήξερες ότι λόγω της σχολής σου μπορείς να αποκτήσεις το athlisis με την μοναδική έκπτωση 48% (και δυνατότητα 2 δόσεων) από
              <strong><del>2100€</del>
              </strong> μόνο <strong>1100€</strong>, χρησιμοποιώντας τον εκπτωτικό κωδικό
            </p>
            <h2 class="text-red mb-4" style="font-weight:900">EDUCATION1100</h2>
            <a href="https://www.athlisis.com" class="font-weight-bold" target="_blank">Επισκέψου το athlisis.com τώρα!</a>`,

    addAthlete: 'Add client',
    addAppointment: 'Add appointment',
    addAthleteModalTitle: 'Quick client creation',
    addAppointmentModalTitle: 'Add appointment',
    events: {
        title: 'Today\'s appointments',
        subtitle: 'View your next appointments for the day',
        noEvents: 'There are no more appointments today!',
        showCancelledQ: 'Show cancelled?',
        cancelled: 'Cancelled',
        dailyAppointments: 'Today\'s Appointment',
        hour: 'Hour',
        client: 'Client - Note'
    },
    todo: {
        title: 'To-do list',
        active: 'Active',
        completed: 'Completed',
        add: 'Add new to-do',
        required: ''
    }
}