export default {
    metaTitle: 'Calendar',
    markAsCancelled: 'Mark as cancelled',
    revokeCancelled: 'Mark as non-cancelled',
    kinds: {
        title: 'Type',
        customer: 'Client appointment',
        general: 'Event / Activity'
    },
    title: 'Title',
    startTime: 'Start time',
    duration: 'Duration',
    endTime: 'End time',
    minutes: '{time} minutes',
    googleMeet: 'Online meeting (Google Meet)',
    addCustomer :'Create new client',
    startGoogleMeet: 'Start meeting (Google meet)',
    create: 'Add appointment',
    update: 'Update appointment',
    delete: 'Delete appointment',
    errors: {
        missingCustomer: 'Please select a client or change the type.'
    },
    untitled: 'Untitled event',
    findTab: '(click to find client\'s tab)',
    onlyWithGoogle: 'This is only applicable if you have connected your Google Calendar.'
}