export default {
    add: 'Create new',
    title: 'Questionnaires',
    subtitle: 'View and edit your questionnaires',
    limit: 'You can create up to 5 questionnaires',
    name: 'Template name',
    edit: {
        title: 'Edit questionnaire',
        name: 'Questionnaire name',
        questionnaire: 'Questionnaire',
        questionPlaceholder: 'Add your question',
        question: 'Question',
        answerType: 'Answer type',
        add: 'Add question',
        update: 'Update question',
        yourQuestion: 'Your question',
        yesNo: 'Choice (yes/no)',
        smallText: 'Text input (small)',
        largeText: 'Text input (large)',

        radios: 'Choice (custom)',
        scale_one_five: 'Scale (1-5)',
        scale_one_ten: 'Scale (1-10)',
        addOptions: 'Options',
        disclaimer: 'Warning! Any change that is being applied will also apply to already saved questionnaires. For example, if you delete an option, it will be gone for all existing questionnaires too!'

    }

}