<template>
  <div>
    <select v-if="showSwitcher === 'true'" :value="$i18n.locale" @change="changeLocale" class="py-1 px-2 bg-transparent">
      <option v-for="(lang, i) in ['el', 'en']" :key="`Lang${i}`" :value="lang">
        {{ lang }}
      </option>
    </select>
  </div>

</template>
<script>
export default{

  data(){
    return{
      showSwitcher: sessionStorage.getItem('ui.showLangSwitcher')
    }
  },
  mounted(){

    this.$nextTick(()=>{
      if(this.$route.query.enable_multilang){
        sessionStorage.setItem('ui.showLangSwitcher', 'true');
        this.showSwitcher = 'true';
      }
    })

  },
  methods: {
    changeLocale(e){
      localStorage.setItem('ui.lang', e.target.value);
      window.location.reload();
    },
  }
}
</script>