export default {
    results: '     <h4>Import results</h4>' +
        '<p CLASS="mt-3">Mass import has been completed successfully. </p>' +
        '<P>The results are summed up below:</P>' +
        '<ul>' +
        '<li>Total clients: {total}</li>' +
        '<li>New clients: {imported}</li>' +
        '<li>Existing clients: {duplicates}</li>' +
        '<li>Failed to import: {failedToImport}</li>' +
        '</ul>',
    content: '<p>Using this functionality, you can imporpt your clients using a CSV file.</p>' +
        '<p>The format of the CSV file is strict and has to follow the specification below  <strong>;</strong>' +
        '</p>' +
        '<p>Τα πεδία πρέπει να βρίσκονται με την σειρά <strong>Όνομα, Επώνυμο, email, ημερομηνία γέννησης, φύλο, κινητό' +
        'τηλέφωνο, κόστος συνεδρίας</strong>. Εάν δεν έχετε κάποιο πεδίο,' +
        'εισάγετε κενή εγγραφή. <br/>Μην διαγράψετε την κεφαλίδα του αρχείου.</p>' +
        '<p>' +
        '<a download href="https://athlisis-new.s3.eu-north-1.amazonaws.com/athlisis-clients-import-template.csv" target="_blank">' +
        'You can download our template by clicking here</a>' +
        '</p><p>If your client is male, type <strong>M</strong>, else type <strong>F</strong>' +
        '</p>p style="font-size:0.9em">Download might not work in Safari. Please use Google Chrome or a separate browser</p>'
}