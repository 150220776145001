export default {
    title: 'Note templates',
    subtitle: 'View and edit your note templates',
    add: 'Create new template',
    name: 'Template name',
    searchPlaceholder: 'Search a template...',
    loadMore: 'Load more...',
    edit: {
        pageTitle: 'Edit notes',
        title: 'Diet plan notes',
        subtitle: 'Type your notes to print with your diet plan.',
        backBtn: 'Back to templates',
        createNew: 'Create new template',
    }
}