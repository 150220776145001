export default{
    back :'Back to list',
    printInstructions: 'Print instructions',
    dietitian: 'Dietitian',
    noResults: 'There is no content in this category.',
    instructions: 'Dietary guidelines for the condition',
    tabs: {
        0: 'Dietary Guidelines',
        1: 'Instructions',
        2: 'Recommended supplements',
        3: 'Diagnostic tools',
        4: 'Theory for study'
    }
}