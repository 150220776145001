<template>


  <div class="text-center tw-py-12">
    <img src="@assets/images/undraw-empty.svg" class="img-elements-not-found" alt="" height="250"/>

    <div class="tw-mt-8">
      <h3>{{title || $t('errors.notFound')}}</h3>
      <p>
        {{subtitle}}
      </p>
      <b-button v-if="btnText" variant="primary" class="mt-2"  @click="$emit('click')">{{btnText}}</b-button>
    </div>

  </div>


</template>

<script>
export default{

  props:{
    title: String,
    subtitle: String,
    btnText: String
  }
}
</script>