<script>
export default {
  components: {},
  created: () => {
    document.body.classList.add('authentication-bg');
    document.body.classList.add('authentication-bg-pattern');
  },
  data(){
    return{
      noVerticalPaddingRoutes: ['shared-recipe']
    }
  }
}
</script>

<template>
<div>
  <div class="account-pages">
    <div class="container d-md-flex h-100 align-items-center  px-md-0">
      <div class="w-100 py-md-0 base-content"
           :class="noVerticalPaddingRoutes.includes($route.name) ? 'py-2 px-1': 'py-5 px-3'"
      >
        <slot />
        <footer class="footer mt-4 footer-alt text-white">2019 - {{new Date().getFullYear()}} © athlisis.com</footer>
      </div>

    </div>


  </div>
 </div>
</template>
<style lang="scss">

.account-pages{


    height:100vh;
    overflow-y:auto;

  .base-content{
    max-height:95%;
  }

}
</style>
