export default {
    yes: 'Yes',
    no: 'No',
    toDo: 'To-Do',
    athletes: 'Clients',
    dietPlan: 'Diet plan',
    dietPlans: 'Diet plans',
    loading: 'Loading',
    viewAll: 'View All',
    options: 'Options',
    filterByTag: {
        title: 'Filter by tag'
    },
    tags: 'Tags',
    add: 'Add',
    existingTag: 'Select an existing tag',
    view: 'View',
    from: 'From',
    to: 'To',
    createdAt: 'Creation date',
    updatedAt: 'Last update',
    dropCsv: 'Drop a CSV file or click here to select',
    dropImg: 'Drop an image or click here to select',
    saveAsPdf: 'Save as PDF',
    print: 'Print',
    colorPick: 'Select color',
    fullName: 'Full-name',
    value: 'Value',
    left: 'L',
    right: 'R',
    leg: 'Leg',
    hand: 'Hand',
    age: {
        years: 'Years',
        months: 'Months',
        days: 'Days',
        hours: 'Hours'
    },
    optionalField: "{field} (optional)",
    preview: 'Preview',
    uploading: 'Uploading...',
    edit: 'Edit'
}
