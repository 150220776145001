<template>
  <div class="col-12 col-md-3 p-4 cloud-base-side-panel">

    <div class="katigories">
      <h4 class="text-primary">{{ $t('cloud.sidebar.categories') }}</h4>
      <ul style="padding-left:0;list-style-type:none;">
        <li>
          <div class="profile-navigation" :class=" currUrl === 'cloud' ? 'active' : ''"
               @click="navigateTo('cloud')">
            {{ $t('cloud.sidebar.home') }}</div>
        </li>
        <li>
          <div class="profile-navigation" :class=" currUrl === 'cloud-diet-plans' ? 'active' : ''" @click="navigateTo('cloud-diet-plans')">
            {{ $t('cloud.sidebar.plans') }}</div>
        </li>
        <li>
          <div class="profile-navigation" :class=" currUrl === 'cloud-recipes' ? 'active' : ''"
               @click="navigateTo('cloud-recipes')">
            {{ $t('cloud.sidebar.recipes') }}</div>
        </li>
        <li>
          <div class="profile-navigation" :class=" currUrl === 'cloud-foods' ? 'active' : ''"
               @click="navigateTo('cloud-foods')">
            {{ $t('foods') }}</div>
        </li>
      </ul>
    </div>
    <div class="bottom-nav ">
      <h4 class="text-primary">{{ $t('cloud.sidebar.profile') }}</h4>
      <ul style="padding-left:0;list-style-type:none;">
        <li>
          <div class="profile-navigation" :class=" currUrl === 'cloud-user-listings' && urlUser === user.id ? 'active' : ''"
               @click="navigateTo('cloud-user-listings',  {userId: user.id})">

            {{ $t('cloud.sidebar.listings') }}</div>
        </li>
        <li>
          <div class="profile-navigation" :class=" currUrl === 'cloud-user' && urlUser === user.id ? 'active' : ''"
               @click="navigateTo('cloud-user',  {userId: user.id})">

            {{ $t('cloud.sidebar.myProfile') }}</div>
        </li>
      </ul>
    </div>


    <b-modal :visible="showModal" id="preview-listing" hide-header hide-footer size="xl" @hidden="closeModal">

      <cloud-listing-preview v-if="showModal" :listing-id="listingId"></cloud-listing-preview>

    </b-modal>

  </div>
</template>

<style lang="scss" scoped>
.katigories{
  min-height:50%;
}
.bottom-nav{
  min-height:50%;
  align-items:end;
}
.profile-navigation{
  padding:10px 15px;
  font-size:1.1em;
  color:#242424;
  cursor:pointer;
  border-radius:12px;
  i{
    padding-right:10px;
    padding-top:2px;
    font-size:1.1em;
  }
  &:hover{
    color:#fff;
    background-color:$color_primary;

  }
  &.active{
    color:#fff;
    background-color:$color_primary;
  }
}
</style>

<script>

import {mapState} from "vuex";
import CloudListingPreview from "@components/cloud/preview/CloudListingPreview";

export default{

  components: {CloudListingPreview},
  computed: {
    ...mapState({
      user: state => state.auth.currentUser,
      showModal: state => state.cloud._showPreviewModal,
      listingId: state => state.cloud._selectedListing
    }),
    currUrl(){
      return this.$route.name
    },
    urlUser(){
      return this.$route.params.userId;
    }
  },
  methods:{
    navigateTo(name, params){
      this.$router.push({name: name, params: params});
    },
    closeModal(){
      this.$store.dispatch('cloud/setShowPreviewModal', false);
    }
  }
}
</script>
