export default {
    content: '<h5 class="mt-3 text-uppercase font-weight-bold">ΜΗ ΔΙΑΘΕΣΙΜΗ ΛΕΙΤΟΥΡΓΙΑ</h5>\n' +
        '      <p>Δεν μπορείς να χρησιμοποιήσεις αυτή την λειτουργία με την εκπαιδευτική έκδοση του athlisis.</p>\n' +
        '\n' +
        '      <p>\n' +
        '        Ήξερες ότι λόγω της σχολής σου μπορείς να αποκτήσεις το athlisis με την μοναδική έκπτωση 48% (και δυνατότητα 2 δόσεων) από\n' +
        '        <strong><del>2100€</del>\n' +
        '        </strong> μόνο <strong>1100€</strong>, χρησιμοποιώντας τον εκπτωτικό κωδικό\n' +
        '      </p>\n' +
        '\n' +
        '      <h2 class="text-red mb-4" style="font-weight:900">EDUCATION1100</h2>\n' +
        '      <a href="https://www.athlisis.com" class="font-weight-bold" target="_blank">Επισκέψου το athlisis.com τώρα!</a>' +
        '',
    customerLimit: 'Έχετε φτάσει το όριο των 10 πελατών',
    unavailable: 'Μη διαθέσιμο στο educational'
}