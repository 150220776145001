export default{
    title: 'Πίνακας ελέγχου',
    welcome: 'Kαλώς όρισες στο athlisis',
    educational: 'Educational',
    welcomeText: 'Tη No1 εφαρμογή για διατροφολόγους στην Ελλάδα!',
    confirmEmail: {
        title: 'Δεν έχετε επιβεβαιώσει το e-mail σας.',
        cta: 'Επαναποστολή συνδέσμου επιβεβαίωσης'
    },
    news: {
        title: 'Τελευταία νέα',
        subtitle: 'Διαβάστε τα τελευταία μας νεά στο blog του athlisis',
        cta: 'Δείτε το άρθρο'
    },

    birthdays: {
        title: 'Πελάτες που έχουν γενέθλια στον μήνα',
        subtitle: 'Μην ξεχάσετε να τους ευχηθείτε!',
        noResults: 'Δεν έχει κανένας πελάτης σας γενέθλια αυτό το μήνα.',
        phone: 'Τηλ:',
        cta: 'Προβολή',
    },
    educationalCta: `
            <p>
              Ήξερες ότι λόγω της σχολής σου μπορείς να αποκτήσεις το athlisis με την μοναδική έκπτωση 48% (και δυνατότητα 2 δόσεων) από
              <strong><del>2100€</del>
              </strong> μόνο <strong>1100€</strong>, χρησιμοποιώντας τον εκπτωτικό κωδικό
            </p>
            <h2 class="text-red mb-4" style="font-weight:900">EDUCATION1100</h2>
            <a href="https://www.athlisis.com" class="font-weight-bold" target="_blank">Επισκέψου το athlisis.com τώρα!</a>`,

    addAthlete: 'Προσθήκη πελάτη',
    addAppointment: 'Προσθήκη ραντεβού',
    addAthleteModalTitle: 'Γρήγορη δημιουργία πελάτη',
    addAppointmentModalTitle: 'Προσθήκη νέου ραντεβού',
    events: {
        title: 'Ραντεβού ημέρας',
        subtitle: 'Δείτε τα επόμενα ραντεβού της ημέρας σας',
        noEvents: 'Δεν έχετε άλλα ραντεβού σήμερα!',
        showCancelledQ: 'Εμφάνιση ακυρωμένων ραντεβού;',
        cancelled: 'Ακυρωμένο',
        dailyAppointments: 'Ραντεβού ημέρας',
        hour: 'Ώρα',
        client: 'Πελάτης - Σημείωση'
    },
    todo: {
        title: 'Λίστα to-do',
        active: 'Ενεργά',
        completed: 'Ολοκληρωμένα',
        add: 'Προσθήκη νέου to-do',
        required: ''
    }
}