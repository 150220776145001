export default {
    dashboard: 'Dashboard',
    athletes: {
        title: 'Clients',
        all: 'View all clients',
        statistics: 'Client statistics',
        new: 'Create new client',
        quickAdd: 'Quick client creation'
    },
    foods: 'Food list',
    dietPlans: {
        title: 'Diet plans',
        all: 'View all plans',
        templates: 'Meal templates',
        statistics: 'Statistics',
        printTemplates: 'Print templates',
        noteTemplates: 'Note templates',
        recipes: 'Recipes'
    },
    scienceTools: 'Scientific tools',
    searchResults: 'Search results',
    aCloud: 'aCloud',
    markAsRead: 'Mark as read',
    unread: 'not read',
    account: 'Account',
    logout: 'Logout',
    news: {
        title: 'News',
        noNews: 'THERE ARE NO NEWS'
    }

}
