export default {
    account: 'Λογαριασμός',
    activationFailed: 'Κάτι πήγε λάθος με την ενεργοποίηση του e-mail!',
    sidebar: {
        dashboard: 'Προφιλ χρήστη',
        company: 'Στοιχεία εταιρείας',
        password: 'Κωδικός πρόσβασης',
        todo: 'Λίστα to-do',
        tags: 'Ετικέτες',
        subscription: 'Πληροφορίες συνδρομής & ανανέωση',
        printTemplates: 'Πρότυπα εκτύπωσης',
        questionnaires: 'Ερωτηματολόγια',
        serial: 'Σειριακός αρ.'
    },
    profile: {
        nonActivatedEmail: 'Δεν έχετε επιβεβαιώσει το e-mail σας.',
        resendActivation: 'Επαναποστολή συνδέσμου επιβεβαίωσης',
        emailActivationError: 'Κάτι πήγε λάθος με την ενεργοποίηση του e-mail!',
        uploadLogo: {
            title: 'Λογότυπο',
            subtitle: 'Ανεβάστε το λογότυπό σας (για εκτυπώσεις κ.λπ.)'
        },
        addRemoveImage: 'Προσθήκη / αλλαγή εικόνας',
        user: {
            title: 'Πληροφορίες Χρήστη',
            subtitle: 'Επεξεργαστείτε τις βασικές σας πληροφορίες & τα κοινωνικά σας δίκτυα',
            insertYour: 'Εισάγετε το {field} σας',
            insertYourShe: 'Εισάγετε τη {field} σας',
            insertYourSheN: 'Εισάγετε την {field} σας',
            bio: 'Βιογραφικό',
            social: {
                title: 'Κοινωνικά δίκτυα',
                website: 'Ιστοσελίδα',
                facebook: 'Facebook',
                instagram: 'Instagram',
                twitter: 'Twitter',
                linkedin: 'LinkedIn'
            }
        }
    },
    imageUpload: {
        profileImage: 'Εικόνα προφίλ',
        hint: 'Πατήστε εδώ ή σύρετε μία εικόνα',
        onlyImg: 'Παρακαλώ εισάγετε εικόνα',
        outOfSize: 'Η εικόνα ξεπερνάει το όριο: ',
        lowestPx: 'Το μέγεθος της εικόνας παραείναι μικρό. Αναμενόμενο ελάχιστο μέγεθος: '
    },
    company: {
        title: 'Στοιχεία Εταιρείας',
        subtitle: 'Εισάγετε τα στοιχεία της τιμολόγησής σας',
        company: 'Επωνυμία εταιρείας',
        companyPlaceholder: 'Εισάγετε την επωνυμία της εταιρείας σας',
        occupation: 'Επάγγελμα',
        vat: 'Α.Φ.Μ',
        doy: 'Δ.Ο.Υ.'
    },
    changePassword: {
        title: 'Αλλαγή Κωδικού Πρόσβασης',
        subtitle: 'Αλλάξτε τον κωδικό σύνδεσής σας στην εφαρμογή',
        password: 'Τωρινός κωδικός πρόσβασης',
        newPassword: 'Νέος κωδικός πρόσβασης',
        placeholders: {
            password: 'Εισάγετε τον τωρινό κωδικό πρόσβασής σας',
            repeatPassword: 'Επαναλάβετε τον νέο κωδικό πρόσβασής σας.',
            newPassword: 'Εισάγετε τον νέο κωδικό πρόσβασής σας.',
        },
        passwordsNoMatch: 'Οι κωδικοί πρόσβασης δεν ταιριάζουν.',
        save: 'Αλλαγή κωδικού πρόσβασης'
    },
    tags: {
        athlete: 'Ετικέτες αθλητών',
        dietPlans: 'Ετικέτες διατροφικών πλάνων',
        templates: 'Ετικέτες πρότυπων γευμάτων',
        edit: 'Επεξεργασία ετικέτας',
        name: 'Όνομα ετικέτας'
    },
    subscriptions: {
        title: 'Πληροφορίες συνδρομής',
        subtitle: 'Μάθετε πότε λήγει η συνδρομή σας & τις χρεώσεις σας',
        secondDoseExpiry: 'Έχετε {days} ημέρες για να εξοφλήσετε τη 2η δόση του athlisis!',
        secondUnpaid: 'Το περιθώριο για την εξόφληση της 2ης δόσης έχει λήξει.',
        renew: 'Κάντε κλικ εδώ για να ανανεώσετε τη συνδρομή σας.',
        cost: 'Κόστος ετήσιας συνδρομής: 149,90€ + Φ.Π.Α.',
        daysUntil: 'Ημέρες μέχρι την επόμενη ανανέωση:',
        remaining: 'Απομένουν {days} μέρες',
        pay: 'Εξόφληση',
        statuses: {
            COMPLETED: 'Ολοκληρωμένη',
            PENDING: 'Αναμένεται πληρωμή'
        },
        remainingLong: '<p>Απομένουν <strong>{days} ημέρες</strong> μέχρι την επόμενη ανανέωση</p>'
    },
    history: {
        title: 'Ιστορικό συναλλαγών',
        subtitle: 'Όλες σας οι συναλλαγές',
        amount: 'Ποσό',
        doses: 'Δόσεις',
        due: 'Εξόφληση έως',
        pay: 'Πληρωμή',
        payNow: 'Πληρωμή τώρα',
        status: 'Κατάσταση',
        type: {
            title: 'Είδος συναλλαγής',
            PURCHASE: 'Αγορά λογισμικού',
            RENEW: 'Ετήσια ανανέωση'
        }
    }

}