export default {
    choose: 'Select a client to start chatting.',
    findAthlete: 'Find tab',
    noMore: 'No more messages!',
    noResults: 'There are no messages!',
    typeHere: 'Type here...',
    send: 'Send',
    recent: 'Recent chats',
    searchPlaceholder: 'Search clients...',
    list: 'Client list'
}