<template>
  <div id="adults-bmi">

    <h5>{{ $t('tools.bmi.title') }}</h5>
    <span class="text-muted">{{ $t('tools.bmi.subtitle') }}</span>

    <div class="row mt-2">
      <div class="col-md-6">
        <b-form-group :label="$t('tools.height')" label-for="edit-height">
          <b-input-group :append="$t('tools.append.height')">
            <b-form-input id="edit-height" v-model="height"
                          :placeholder="$t('tools.bmi.placeholder.height')"
                          type="text"
                          @input="height = replaceCommaWithDot(height)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group :label="$t('tools.weight')"  label-for="edit-weight">
          <b-input-group :append="$t('tools.append.weight')">
            <b-form-input id="edit-weight" v-model="weight"
                          :placeholder="$t('tools.bmi.placeholder.weight')" type="text"
                          @input="weight = replaceCommaWithDot(weight)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="col-md-12 text-right">
        <b-button variant="primary" @click="calculate">{{ $t('tools.bmi.btn') }}</b-button>
      </div>
      <div v-if="bmi" class="col-md-12 text-center mt-3">
        <b-alert :variant="resultColor" show><strong><h4>{{ result }}</h4></strong> {{ $t('tools.bmi.result.prefix') }}: {{ bmi.toFixed(1) }}
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  data() {
    return {
      height: null,
      weight: null,
      bmi: null,
      result: null,
      resultColor: '',
      results: [0,1,2,3,4,5].map(x => this.$t(`tools.bmi.result.${x}`)),
      resultColors: ['warning', 'success', 'warning', 'danger', 'danger', 'danger'],
    }
  },

  methods: {
    calculate() {
      this.bmi = this.weight / Math.pow(this.height / 100, 2);
      let index = 0;
      if (this.bmi < 18.4) index = 0;
      else if (this.bmi < 24.9) index = 1;
      else if (this.bmi < 29.9) index = 2;
      else if (this.bmi < 34.9) index = 3;
      else if (this.bmi < 39.9) index = 4;
      else index = 5;

      this.result = this.results[index];
      this.resultColor = this.resultColors[index];
    }
  }
}
</script>
