export default {
    questionnaire: 'Ερωτηματολόγιο',
    add: 'Προσθήκη ερωτηματολογίου',
    short: 'Σύντομο Ιστορικό',
    long: 'Εκτενές Ιστορικό',
    personal: 'Προσωπικά Ερωτηματολόγια',
    shareBtn: 'Κοινοποίηση ερωτηματολογίου σε πελάτη',
    previewBtn: 'Προβολή συμπληρωμένων απαντήσεων',
    printBtn: 'Εκτύπωση',
    share: {
        title: 'Κοινοποίηση ιστορικού προς συμπλήρωση',
        active: 'Η κοινοποίηση ερωτηματολογίου είναι ενεργή και ο πελάτης σας μπορεί να δει και να επεξεργαστεί τις απαντήσεις.',
        inactive: 'Η κοινοποίηση ερωτηματολογίου δεν είναι ενεργή. Ο πελάτης σας δεν μπορεί να δει και να επεξεργαστεί τα ερωτηματολόγια.',
        whichQ: 'Ποια ερωτηματολόγια θέλετε να μπορεί να δει / συμπληρώσει ο πελάτης σας;',
        copy: 'Αντιγραφή συνδέσμου κοινοποίησης',
        enable: 'Ενεργοποίηση κοινοποίησης',
        shareShortQ: 'Κοινοποίηση Σύντομου ιστορικού;',
        shareLongQ: 'Κοινοποίηση ιστορικού;',
        sharePersonalQ: 'Κοινοποίηση Προσωπικών ερωτηματολόγια;',
    },
    print: {
        title: '',
        printShortQ: 'Εκτύπωση σύντομου ιστορικού;',
        printLongQ: 'Εκτύπωση ιστορικού;',
        printPersonalQ: 'Προσωπικά ερωτηματολόγια;'
    },
    answered: 'Προβολή συμπληρωμένων απαντήσεων'
}