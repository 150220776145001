/** PATHISIS **/
export const PATHISIS_CATEGORIES = 'pathisis.categories';
export const PATHISIS_CATEGORY = (cat) => `pathisis.category-${cat}`;
export const PATHISIS_SELECTED_CATEGORY = 'pathisis.selected_category'


/** FOODS **/
export const FOODS_LIST = 'food.foods';
export const FOODS_DRI = 'food.dris';

export const FOODS_COPIED_MEAL_ID = 'food.copied_meal_id';
export const FOODS_COPIED_DAY = 'food.copiedDay'
