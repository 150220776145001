export default {
    yes: 'Ναι',
    no: 'Όχι',
    toDo: 'To-Do',
    athletes: 'Πελάτες',
    dietPlan: 'Διατροφικό πλάνο',
    dietPlans: 'Διατροφικά πλάνα',
    loading: 'Φόρτωση',
    viewAll: 'Προβολή όλων',
    options: 'Επιλογές',
    filterByTag: {
        title: 'Φιλτράρισμα ανά ετικέτα'
    },
    tags: 'Ετικέτες',
    add: 'Προσθήκη',
    existingTag: 'Επιλέξτε μια υπάρχουσα ετικέτα',
    view: 'Προβολή',
    from: 'Από',
    to: 'Έως',
    createdAt: 'Ημερομηνία δημιουργίας',
    updatedAt: 'Τελευταία τροποποίηση',
    dropCsv: 'Σύρτε εδώ ένα αρχείο csv ή πατήστε πάνω στο πλαίσιο',
    dropImg: 'Σύρτε εδώ μια εικόνα ή πατήστε στο πλαίσιο',
    saveAsPdf: 'Αποθήκευση ως PDF',
    print: 'Εκτύπωση',
    colorPick: 'Επιλογή χρώματος',
    fullName: 'Ονοματεπώνυμο',
    value: 'Τιμή',
    left: 'Α',
    right: 'Δ',
    leg: 'Πόδι',
    hand: 'Χέρι',
    age: {
        years: 'Ετών',
        months: 'Μηνών',
        days: 'Ημερών',
        hours: 'Ωρών'
    },
    optionalField: "{field} (προαιρετικό)",
    preview: 'Προεπισκόπηση',
    uploading: 'Μεταφόρτωση...',
    edit: 'Επεξεργασία'
}
