export default {
    title: 'Recipes',
    missingName: 'Please type a name for your recipe',
    add: 'Create new recipe',
    empty: 'NO RECIPES FOUND',
    emptyDesc: 'You haven\'t saved any recipes.',
    name: 'Recipe name',
    create: 'Create recipe',

    photo: 'Recipe photo',
    recipe: 'Recipe',
    ingredients: 'Ingredients',
    execution: 'Instructions',
    share: 'Share recipe',
    dontForget: 'Do not forget to click',
    changeImage: 'Change image',
    deleteImage: 'Delete image',
    insertFoodAs: 'Add food to diet plans',
    per100: 'Per 100gr.',
    perServing: 'Per serving',
    howManyServings: 'How many servings is this recipe for',
    serving: 'Serving',
    servings: 'Servings',
    editRemoveIngredients: 'Edit / add ingredients',
    copy: {
        title: 'Are you sure you want to copy this recipe?',
        body: 'An exact replica of this recipe will be created, as well as a new food in your list.',
    },
    label: {
        base: 'Base Information',
        save: 'Save label',
        micro: 'Micronutrients',
        microNutrients: 'Micronutrients',
        nutritionValue: 'Nutrition Value',
        dietitian: 'Dietitian',
        modal: {
            title: 'Choose label micronutrients',
            text: 'Choose which micronutrients to show in your label',
            selectAll: 'Select all',
            unselectAll: 'De-select all'
        }
    }
}