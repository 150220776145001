export default{
    user: 'User',
    profile: {
        subtitle: 'Explore user\'s profile',
        listings: {
            title: 'My listings',
            subtitle: 'View and edit your athlisis cloud listings.'

        },
        edit: 'Edit listing',
    },
    ordering: {
        latest: 'Newest',
        oldest: 'Oldest',
        downloads: 'Most downloads',
        likes: 'Most likes'
    },
    sidebar: {
        categories: 'Categories',
        home: 'Home',
        plans: 'Diet plans',
        recipes: 'Recipes',
        profile: 'Profile',
        listings: 'My listings',
        myProfile: 'My profile',
    },
    home: {
        title: 'Welcome to Athlisis Cloud 👋',
        subtitle: 'A place where athlisis dietitians can easily share diet plans, foods, recipes, templates.',
    },
    plans: {
        title: 'Diet plans',
        subtitle: 'Explore & share diet plans. Use tags and search to find new diet plans ideas, for special or non-special cases.'
    },
    meals: {
        title: 'Coming soon',
        subtitle: 'Explore & share diet plans. Use tags and search to find new diet plans ideas, for special or non-special cases',
    },
    searchByName: 'Search by name...',
    tags: 'Search by tag',
    recipes: {
        title: 'Recipes',
        subtitle: 'Explore & share recipes. Use tags and search to find new recipe ideas'
    },
    foods: {
        title: 'Foods',
        subtitle: 'Find custom foods created from recipes, meals, plans etc.'
    },
    popularPlans: 'Popular diet plans',
    noPlans: 'No diet plans were found.',
    popularRecipes: 'Popular recipes',
    noRecipes: 'No recipes found.',
    downloads: 'Total downloads',
    likes: 'Total likes',
    back: 'Back',

    discussion: 'Conversation / comments',
    plan: {
        tabs: {
            0: 'Diet Plan',
            1: 'Plan Analysis',
        }
    },
    save: 'Save to my templates',
    saved: 'Saved successfully to your templates.',
    comment: 'Your comment',
    noComments: 'No comments found.',
    commentDisclaimer: 'Messages that result in creating arguments / bad atmosphere are prohibited.<br/> Respect all members even if you disagree. Respect everyone no matter what the circumstances are.<br>\n' +
        '            <strong>Failure to comply with the terms may result in exclusion from Athlisis Cloud.</strong>',
    average: {
        proteins: 'Proteins average  <br>(only days with foods)',
        carbs: 'Carbohydrates average  <br>(only days with foods)',
        fats: 'Fats average  <br>(only days with foods)',
        kcal: 'Energy (kcal) average <br>(only days with foods)'
    },
    dpSuccess: 'Diet plan was saved successfully! You can find it in your "template" diet plans!',
    recipeSuccess: 'Recipe was saved successfully!',
    saveTemplateModal: {
        title: 'Are you sure you want to proceed?',
        body: '<p>If you proceed with saving, there will also be <strong>{count} foods</strong> added in your food database from our cloud library (if they don\'t already exist).</p>' +
            '<p>The new foods will be:<br> <strong>{foods}</strong></p>',
        okText: 'Save template'
    }

}