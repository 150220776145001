<template>
  <div id="insulin-resistance">
    <h5>{{ $t('tools.insulin.menu') }}</h5>

    <div class="row mt-4">
      <div class="col-md-6">
        <b-form-group :label="$t('tools.insulin.triglycerides')" label-for="edit-triglycerides">
          <b-input-group :append="$t('tools.append.mg_dl')" >
            <b-form-input id="edit-triglycerides" v-model="triglycerides" type="text"
                          :placeholder="$t('tools.insulin.triglycerides')"
                          @input="triglycerides = replaceCommaWithDot(triglycerides)"></b-form-input>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group :label="$t('tools.insulin.glucose')" label-for="edit-glucose">
          <b-input-group  :append="$t('tools.append.mg_dl')" >
            <b-form-input id="edit-glucose" v-model="glucose" type="text"
                          :placeholder="$t('tools.insulin.glucose')"
                          @input="glucose = replaceCommaWithDot(glucose)"></b-form-input>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="col-md-12 text-right">
        <b-button variant='primary' @click="calculate">{{ $t('tools.insulin.btn') }}</b-button>
      </div>
      <div v-if="result" class="col-md-12 text-center mt-3">


        {{ $t('tools.insulin.result.prefix') }}: <strong>{{tyg}}</strong>
        <h4 v-html="$t('tools.insulin.result.title', {result})"/>
        <h4 v-if="parseFloat(tyg) >= 8.5"> {{ $t('tools.insulin.result.nafld') }}</h4>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  data() {
    return {
      triglycerides: null,
      glucose: null,
      result: null,
      tyg: null
    }
  },
  methods:{
    calculate(){
      this.tyg = parseFloat(this.triglycerides) * parseFloat(this.glucose) / (2 * 1000);
      if(this.tyg >= 4.49){
        this.result = this.$t('tools.insulin.low');
      }else{
        this.result = this.$t('tools.insulin.high')
      }
    }
  }
}

</script>
