<template>

  <div id="listing-preview">

    <div class="row">
      <div class="col-12 col-md-6">
        <router-link v-if="item.user" :to="{name: 'cloud-user', params:{userId: item.user.id}}" class="text-primary font-weight-bold">
          <v-gravatar v-if="item.user" :email="item.user.email" alt="user-image" class="rounded-circle" default-img="mp"
                      style="max-width:25px"
          />
          {{ item.user.firstName }} {{ item.user.lastName }}
        </router-link>

        <span v-if="!item.foodId" class="mr-2">💾 {{ item.downloads }}</span>
        <span v-if="!item.foodId" class="mr-2">❤️ {{ item.likes }}</span>

        <h2>{{ item.title }}</h2>
        <p>{{ item.text }}</p>
        <b-alert v-if="isSaved" show size="xl" variant="success">{{ $t('cloud.saved') }}</b-alert>
      </div>

      <div class="col-12 col-md-6 text-right">
        <b-button v-if="item.userHasLiked" :disabled="isChangingLike" class="mr-2" variant="danger" @click="unlikeItem">
          ❤
        </b-button>
        <b-button v-else :disabled="isChangingLike" class="mr-2" variant="outline-danger" @click="likeItem">
          ❤
        </b-button>


        <save-button v-if="!isSaved && !item.foodId" :disabled="isSaving"
                     :text="$t('cloud.save')" @click="saveToPersonalSpace"
        ></save-button>
      </div>
    </div>


    <div v-if="item.dietPlan">

      <cloud-diet-plan-preview :diet-plan="item.dietPlan" :listing-id="item.id"></cloud-diet-plan-preview>

    </div>

    <div v-if="item.food">
      <cloud-food-preview :food="item.food" :listing-id="item.id"></cloud-food-preview>
    </div>

    <div v-if="item.recipe">
      <cloud-recipe-preview :listing-id="item.id" :recipe="item.recipe"></cloud-recipe-preview>
    </div>
  </div>
</template>


<script>

import CloudDietPlanPreview from "@components/cloud/preview/CloudDietPlanPreview";
import CloudFoodPreview from "@components/cloud/preview/CloudFoodPreview";
import CloudRecipePreview from "@components/cloud/preview/CloudRecipePreview";

export default {

  components: {CloudDietPlanPreview, CloudFoodPreview, CloudRecipePreview},
  props: {
    listingId: String
  },
  methods: {

    fetchListingContent() {
      this.$axios.get('/cloud/listings/' + this.listingId).then(res => {
        this.item = res.data;
        if (this.item.dietPlanId) this.countCustomDietPlanFoods();
        if (this.item.recipeId) this.countCustomRecipeFoods();

      }).catch(e => {
      });
    },

    saveToPersonalSpace() {
      this.isSaving = true;
      if (this.customFoodCount > 0) {
        this.$dialog.confirm({
          title: this.$t('cloud.saveTemplateModal.title'),
          body: this.$t('cloud.saveTemplateModal.body', {
            count: this.customFoodCount,
            foods: this.customFoodNames.join(", ")
          })
        }, {
          html: true,
          loader: true,
          okText: this.$t('cloud.saveTemplateModal.okText'),
          cancelText: this.$t('cancel'),
          backdropClose: true
        }).then((dialog) => {
          if (this.item.dietPlanId) {
            this.saveDpToPersonalSpace(dialog);
          }
          if (this.item.recipeId) {
            this.saveRecipeToPersonalSpace(dialog);
          }

        }).catch(() => {
        });
      } else {
        if (this.item.dietPlanId) {
          this.saveDpToPersonalSpace();
        }
        if (this.item.recipeId) {
          this.saveRecipeToPersonalSpace();
        }
      }

    },

    saveDpToPersonalSpace(dialog) {
      this.$axios.post('/cloud/copy/diet-plan', {cloudDietPlanId: this.item.dietPlanId}).then(res => {
        this.$notify({
          group: 'athlisis-notifications', duration: 5000, type: 'success',
          title: this.$t('success.title'),
          text: this.$t('cloud.dpSuccess')
        });

        for (let food of res.data.newFoods) {
          this.$store.dispatch('food/addNewFood', food);
        }
        this.isSaved = true;
        if (dialog) dialog.close();
      }).catch(e => {
      });
    },

    saveRecipeToPersonalSpace(dialog) {
      this.$axios.post('/cloud/copy/recipe', {recipeId: this.item.recipeId}).then(res => {
        this.$notify({
          group: 'athlisis-notifications', duration: 5000, type: 'success',
          title: this.$t('success.title'),
          text: this.$t('cloud.recipeSuccess')
        });

        for (let food of res.data.newFoods) {
          this.$store.dispatch('food/addNewFood', food);
        }
        this.isSaved = true;
        if (dialog) dialog.close();
      }).catch(e => {
      });
    },

    countCustomDietPlanFoods() {
      const foods = this.item.dietPlan.meals.map(x => x.foods);
      let merged = [].concat.apply([], foods);
      this.customFoodNames = [...new Set(merged.filter(x => x.isSystem === false).map(x => x.name))];
      this.customFoodCount = this.customFoodNames.length;
    },

    countCustomRecipeFoods() {
      this.customFoodNames = [...new Set(this.item.recipe.meal.foods.filter(x => x.isSystem === false).map(x => x.name))];
      this.customFoodCount = this.customFoodNames.length;
    },

    unlikeItem() {
      this.isChangingLike = true;
      this.$axios.post('/cloud/listings/' + this.listingId + '/unlike').then(res => {
        this.item.userHasLiked = false;
        this.item.likes--;
      }).catch(e => {

      }).finally(() => {
        this.isChangingLike = false;
      });

    },
    likeItem() {
      this.isChangingLike = true;
      this.$axios.post('/cloud/listings/' + this.listingId + '/like').then(res => {
        this.item.userHasLiked = true;
        this.item.likes++;
      }).catch(e => {

      }).finally(() => {
        this.isChangingLike = false;
      });
    }

  },
  mounted() {
    this.fetchListingContent()
  },
  data() {
    return {
      item: {},
      isSaved: false,
      customFoodCount: 0,
      customFoodNames: [],
      isSaving: false,
      isChangingLike: false
    }
  },
}
</script>
