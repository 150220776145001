export default {
    login: {
        title: 'Login',
        metaTitle: 'Login to athlisis',
        subtitle: 'Please type your e-mail and password to connect to the application.',
        otpTooManyFails: 'You\'ve inserted the wrong 4-digit code more than 5 times. Please try again after the current code expires.',
        otpFail: 'Wrong 4-digit code',
        loginBtn: 'Login',
        loginEducational: 'Login for students',
        forgotPwdBtn: 'Forgot your password?',
        noAccountBtn: 'First time in Athlisis?',
        registerBtn: 'Register',
        email: 'E-mail',
        password: 'Password',
        emailPlaceholder: 'Insert your e-mail',
        passwordPlaceholder: 'Insert your password',
        otp: {
            title: 'Insert your OTP',
            text: 'Type your 4-digit code that was sent to your e-mail.',
            remaining: 'Remaining',
            noCode: 'Didn\'t receive a code?',
            resend: 'Send e-mail again',
            code: '4-digit code',
            issue: 'If you\'re facing an issue, contact us at'
        },
        googleResetPassword: 'You have not setup a regular password due to registering with your Google account.',
        googleResetPasswordCta: 'Click here to reset your password'
    },
    register: {
        title: 'Register',
        metaTitle: 'Register for athlisis',
        created: 'Your account was created successfully!',
        subtitle: 'No account yet?<br> Create one now and become a member of <strong>athlisis.</strong>',
        registerBtn: 'Register',
        lastName: 'Surname',
        placeholder: {
            name: 'Insert your first name',
            lastName: 'Insert your last name',
            password: 'Insert a password',
        },
        hasAccount: 'Already have an account?',
        hasAccountBtn: 'Login'
    },

    confirm: {
        metaTitle: 'Confirm account',
        metaDesc: 'Confirm your athlisis account',
        title: 'Your account was confirmed successfully.',
        emailSent: 'An e-mail has been sent to your e-mail address',
        emailInfo: 'Please check your e-mail for a confirmation link to complete your registration.',
        backToLogin: 'Back to login'
    },
    forgot: {
        metaTitle: 'Reset password',
        metaDesc: 'Reset your athlisis password',
        success: 'An e-mail was sent with recovery instructions!',
        title: 'Please type your email address and we will send you an e-mail with recovery instructions.',
        resetBtn: 'Reset password',
        hasAccount: 'Already have an account?',
        hasAccountBtn: 'Login'
    },
    recover: {
        metaTitle: 'Restore password',
        metaDesc: 'Restore your athlisis password',
        passwordNoMatch: 'Passwords do not match!',
        repeat: 'Repeat your password',
        repeatPlaceholder: 'Repeat your password',
        title: 'Restore password',
        subtitle: 'Type a new password you will use to login to the software!',
        changeBtn: 'Change your password',

    },
    mobileRecover: {
        title: 'Restore password',
        subtitle: 'Please use a new password to use when connecting to Athlisis App.',
        success: 'Your password changed successfully!'
    },

    paused: {
        title: 'Your account is disabled',
        subtitle: 'Your account has been disabled. For more information, please contact us at info@athlisis.com',
        button: 'Re-try loading',
        logout: 'Logout'
    }

}