import axios from 'axios'
import {getSavedState, saveState} from "@utils/util";


export const state = {
    _selectedListing: null,
    _showPreviewModal: false,
    _cloudTags: getSavedState('cloud.tags'),
    _dpQuery: null,
    _foodQuery: null,
    _recipeQuery: null
};

export const getters = {
};

export const mutations = {

    SET_SELECTED_LISTING(state, listing){
        state._selectedListing = listing;
    },

    SET_SHOW_PREVIEW_MODAL(state, val){
        state._showPreviewModal = val;
    },

    SET_CLOUD_TAGS(state, val){
        state._cloudTags = val;
        saveState('cloud.tags', state._cloudTags);
    },
    SET_DP_QUERY(state, val){
        state._dpQuery = val;
    },
    SET_FOOD_QUERY(state, val){
        state._foodQuery = val;
    },
    SET_RECIPE_QUERY(state, val){
        state._recipeQuery = val;
    }

};

export const actions = {

    async init({state, dispatch, commit}){

        axios.get(process.env.VUE_APP_API + '/cloud/listings/tags').then(result => {
            commit('SET_CLOUD_TAGS', result.data);
        }).catch(e=>{
            console.error("Failed to fetch cloud listing tags");
        });
    },


    setDietPlanQuery({commit}, val){
        commit('SET_DP_QUERY', val);
    },
    setFoodQuery({commit}, val){
        commit('SET_FOOD_QUERY', val);
    },
    setRecipeQuery({commit}, val){
        commit('SET_RECIPE_QUERY', val);
    },
    setSelectedListing({commit}, id){
        commit('SET_SELECTED_LISTING', id);
    },

    setShowPreviewModal({commit}, id){
        commit('SET_SHOW_PREVIEW_MODAL', id);
    },

    setCloudTags({commit}, tags){
        commit('SET_CLOUD_TAGS', tags);
    },

    reset({commit}){
        commit('SET_SELECTED_LISTING', null);
        commit('SET_SHOW_PREVIEW_MODAL', null);
    }
};