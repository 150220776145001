export default {
    one: 'Food',
    add: 'Create food',
    noEditSystem: 'You cannot edit system\'s foods.',
    editingSystem: 'WARNING! Editing a system food.',
    name: 'Name',
    category: 'Category',
    chooseCategoryError: 'Please select a category',
    measure: 'Measurement unit',
    chooseType: 'Choose food type',
    attributes: {
        choose: 'Select attributes',
        isVeganQ: 'Is it vegan?',
        isFastingQ: 'Is it suitable for fasting?'
    },
    loading: 'Loading...',
    noFoods: 'No foods found.',
    noMoreFoods: 'No more foods.',
    wrongQuantity: 'Please select a correct quantity for the food.',
    trixopoulouContent: 'Recipe details',
    noTrixopoulouContent: 'We couldn\'t find the recipe\'s details!',
    lists: {
        '0': 'Athlisis foods',
        '1': 'Trixopoulou (micronutrients)',
        '2': 'USDA',
        '3': 'Recipes',
        '4': 'My foods',
        '5': 'aCloud',
        favourites: 'Favourite foods'
    },
    categories: {
        fresh: 'Fresh foods',
        carb: 'Carb-based',
        fat: 'Fat-based',
        protein: 'Protein-based',
        complex: 'Complex',
    },
    stats: {
        macros: 'Macronutirents',
        fats: 'Fats',
        fresh: 'Fresh',
        amylouxes: 'Αμυλούχες',
        animal: 'Ζωϊκές',
        complex: 'Σύνθετα',
        drinks: 'Ποτά / Γενικά',
        stats: 'Στατιστικά Τροφίμων'
    },
    dbCategories: {
        uncategorized: 'No category',
        fresh: 'Φρέσκιες',
        amylouxes: 'Αμυλούχες',
        fats: 'Λίπη',
        animal: 'Ζωϊκές',
        complex: 'Σύνθετα τρόφιμα',
        general: 'Ποτά / Γενικά',

    },
    foodTemplates: 'Food templates',
    freeTemplates: 'Free templates',
    create: 'Create new food',
    showQuantity: 'Show quantities',
    hideQuantity: 'Hide quantities',
    avoidedFood: 'Foods client avoids',
    likedFood: 'Foods client likes',
    shortQuestionnaire: 'Short questionnaire',
    longQuestionnaire: 'General questionnaire',
    dri: 'DRI',
    dayProgress: 'Day progress',
    mealProgress: 'Meal progress',
    carbohydrationWarning: 'Statistics have been altered to achieve carbohydration!',
    tomatoes: 'Tomatoes',
    bread: 'Bread',
    oliveOil: 'Olive oil',
    cheese: 'White Cheese'
}