export default {
    add: 'Προσθήκη νέου πλάνου',
    systemOne: 'Πρότυπο συστήματος',
    system: 'Πρότυπα συστήματος',
    personal: 'Προσωπικά πρότυπα',
    foodPlan: 'Εξατομικευμένο πλάνο',
    freePlan: 'Ελεύθερο πλάνο',
    foodPlans: 'Εξατομικευμένα διατροφικά πλάνα',
    freePlans: 'Ελεύθερα διατροφικά πλάνα',
    empty: 'ΔΕΝ ΒΡΕΘΗΚΑΝ ΔΙΑΤΡΟΦΙΚΑ ΠΛΑΝΑ',
    emptyText: 'Δεν βρέθηκε ακόμα κάποιο διατροφικό πλάνο σε αυτόν τον πελάτη (ή τον λογαριασμό)',
    types: {
        system: ''
    },
    withDri: '(με DRI %)',
    withoutDri: '(χωρίς DRI)',
    micro: 'Μικροθρεπτικό',
    dri :'DRIs(%)',
    up: 'Ανεκτά Ανώτατα Επίπεδα Πρόσληψης (UP)',
    dayPercentage: '% ημέρας',
    stats: {
        title: 'Στατιστικά διατροφικών πλάνων',
        subtitle: 'Δείτε χρήσιμα στατιστικά για τα διατροφικά πλάνα σας',
        maintainWeight: 'Συντήρηση Βάρους',
        loseWeight: 'Μείωση Βάρους',
        addWeight: 'Αύξηση Βάρους',
        plans: 'πλάνα',
        vegan: 'Vegan',
        fasting: 'Νηστίσιμα',
        carbohydration: 'Υδατανθρακώσεις',
        goalPerDp: 'Στόχοι ανά διατροφικό πλάνο',
    },
    microTranslation: {
        saturatedFats: 'Κορεσμένα Λιπαρά (g)',
        monounsaturatedFats: 'Μονοακόρεστα Λιπαρά (g)',
        polyunsaturatedFats: 'Πολυακόρεστα λιπαρά (g)',
        cholesterol: 'Χοληστερόλη (mg)',
        monodisaccharides: 'Μονοδισακχαρίτες (g)',
        polysaccharides: 'Πολυσακχαρίτες (g)',
        dietaryFibers: 'Διαιτητικές Ίνες (g)',
        water: 'Νερό (g)',
        alcohol: 'Αλκοόλη (g)',
        natrium: 'Νάτριο (mg)',
        potassium: 'Κάλιο (mg)',
        calcium: 'Ασβέστιο (mg)',
        magnesium: 'Μαγνήσιο (mg)',
        phosphorus: 'Φώσφορος (mg)',
        iron: 'Σίδηρος (mg)',
        zinc: 'Ψευδάργυρος (mg)',
        retinol: 'Ρετινόλη (mcg)',
        carotene: 'Καροτίνη (mcg)',
        thiamine: 'Θειαμίνη (mg)',
        riboflavin: 'Ριβοφλαβίνη (mg)',
        vitaminB6: 'Βιταμίνη Β6 (mg)',
        vitaminE: 'Βιταμίνη Ε (mg)',
        vitaminC: 'Βιταμίνη C (mg)',
        sugars: 'Σάκχαρα (g)',
        vitaminA: 'Βιταμίνη Α (mcg)',
        caroteneA: 'Καροτίνη Α (mcg)',
        caroteneB: 'Καροτίνη Β (mcg)',
        cryptoxanthin: 'Κρυπτοξανθίνη Β (mcg)',
        lycopene: 'Λυκοπένιο (mcg)',
        lutein_zeaxanthin: 'Λουτεΐνη + ζεαξανθίνη (mcg)',
        niacin: 'Νιασίνη (mg)',
        folicAcid: 'Φολικό οξύ (mcg)',
        folateFood: 'Φολικό, τρόφιμο (mcg)',
        folateDFE: 'Φολικό, DFE (mcg)',
        folateTotal: 'Φολικό, σύνολο (mcg)',
        choline: 'Χολίνη (mg)',
        vitaminB12: 'Βιταμίνη Β12 (mcg)',
        vitaminB12Added: 'Βιταμίνη Β12, πρόσθετη (mcg)',
        vitaminD: 'Βιταμίνη D (D2+D3) (mcg)',
        vitaminK: 'Βιταμίνη Κ (mcg)',
        copper: 'Χαλκός (mg)',
        selenium: 'Σελήνιο (mcg)',
        caffeine: 'Καφεΐνη (mg)',
        theobromine: 'Θεοβρωμίνη (mg)',
    },
}