
import axios from "axios";
import {getSavedState, saveState} from "@utils/util";

export const state = {

    notifications: getSavedState('notifications.notifications'),
    notificationsCount: getSavedState('notifications.notificationCount'),
    lastUpdate: getSavedState('notifications.lastUpdate')

};

export const mutations = {

    CACHE_NOTIFICATIONS(state, notifications){
        state.notifications = notifications;
        saveState('notifications.notifications', notifications);
    },

    CACHE_NOTIFICATIONS_COUNT(state, count){
        state.notificationsCount = count;
        saveState('notifications.notificationCount', count);
    },

    CACHE_UPDATED_TIME(state, time){
        state.lastUpdate = time;
        saveState('notifications.lastUpdate', time);
    }


};

export const actions = {

    async init({ state, dispatch }) {
        if(localStorage.getItem('auth.token') !== 'null') {
            dispatch('refreshCacheNotifications');
        }
    },

    async cacheNotifications({commit}){
        console.warn('fetching notifications...');

        const params = {
            limit: 3,
            offset: 0
        };

        axios.get(process.env.VUE_APP_API + `/notifications/`, {params: params}).then(result => {
            commit('CACHE_NOTIFICATIONS', result.data.rows);
            commit('CACHE_UPDATED_TIME', new Date());
        }).catch(e=>{
            console.error("failed to fetch notifications");
            // this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η φόρτωση των ειδοποιήσεων απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
        });

        axios.get(process.env.VUE_APP_API + `/notifications/count`).then(result => {
            commit('CACHE_NOTIFICATIONS_COUNT', result.data);
        }).catch(e=>{
            console.error("failed to fetch notifications count");
        });

    },

    async refreshCacheNotifications({commit, dispatch}){
        if(!state.lastUpdate || state.lastUpdate === 'null' || timeBetween(new Date(), new Date(state.lastUpdate)) >= 1){
            return dispatch('cacheNotifications');
        }
    },
    reset({commit}){
        commit('CACHE_NOTIFICATIONS', null);
        commit('CACHE_UPDATED_TIME', null);
    }
};


function timeBetween(date1, date2) {

    // The number of milliseconds in one day
    const MINUTES_30 = 1000 * 60 * 30;

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(date1 - date2);

    // Convert back to days and return
    return Math.round(differenceMs / MINUTES_30);

}
