
const convertFlattenedToNested = (obj) =>  {
    const nestedObj = {};
    for (const key in obj) {
        const keys = key.split('.');
        keys.reduce((acc, cur, index) => acc[cur] ??= (index === keys.length - 1 ? obj[key] : {}), nestedObj);
    }
    return nestedObj;
}

export const convertCloudMealFoodToSystemMealFoodFormat = (cloudFood) => {
    const flattened = convertFlattenedToNested(cloudFood);
    return {
        food: {
            measures: cloudFood.measures,
            name: cloudFood.name,
            category: cloudFood.category,
            proteins: cloudFood.proteins,
            carbohydrates: cloudFood.carbohydrates,
            fats: cloudFood.fats,
            isSystem: cloudFood.isSystem,
            kcal: cloudFood.kcal,
            measurementUnit: cloudFood.measurementUnit,
            microNutrients: cloudFood.microNutrients,
            quantity: cloudFood.quantity,
        },
        ...flattened.mealFoods
    }
}