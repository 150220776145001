export default {
    betaWarning: 'Auto-generate a diet plan - Beta version.',
    days: 'Which days would you like to generate? (existing contents will be deleted)',
    enableAll: 'Enable all',
    disableAll: 'Disable all',
    calories: 'How many calories?',
    sameDinner: 'Use the same lunch/dinner?',
    sameDinner2: 'always selects lunch as the meal template category',
    calorieDistribution: 'How would like to distribute the calories across the meals? (Type 0 to ignore a meal)',
    intro: '<p>\n' +
        '        Η αυτόματη δημιουργία διατροφικού πλάνου λειτουργεί με βάση τα δικά σας πρότυπα γεύματα. Για να έχετε καλύτερη\n' +
        '        εμπειρία χρήσης, θα χρειαστεί να φτιάξετε\n' +
        '        σωστά και ισορροπημένα πρότυπα ώστε το τελικό διαιτολόγιο να έχει σωστές συνθέσεις τόσο σε συνδυασμούς τροφίμων\n' +
        '        όσο και σε κατανομή μακροθρεπτικών συστατικών.\n' +
        '      </p>\n' +
        '      <p>\n' +
        '        Η ακρίβεια των ποσοτήτων καθορίζεται ανά 10 γραμμάρια σε τρόφιμα που έχουν μονάδα μέτρησης τα γραμμάρια και ανά\n' +
        '        μισό (τεμάχιο, κουταλάκι, φλυτζάνι κ.λπ.) για τις υπόλοιπες μονάδες μέτρησης.\n' +
        '      </p>\n' +
        '      <p>\n' +
        '        Το σύστημα δημιουργήθηκε για να σας βοηθήσει να κάνετε γρηγορότερα τη δουλειά σας και όχι να σας αντικαταστήσει.\n' +
        '        <strong>Παρακαλούμε να δώσετε προσοχή στο διαιτολόγιο και τις επιλογές του πριν το δώσετε σε κάποιον\n' +
        '          διαιτώμενο.</strong>\n' +
        '\n' +
        '      </p>\n' +
        '      <p>\n' +
        '        <strong>Εάν δεν έχετε πρότυπα γεύματα, δεν μπορεί να λειτουργήσει η αυτόματη παραγωγή διαιτολογίων.</strong>\n' +
        '      </p>',
}