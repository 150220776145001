import i18n from "@utils/setupi18n";
export const localStorageKeys = {
    progressListToggle: 'ui.progressListToggle'
}
export const getSavedState = (key) => {
    try{
        return JSON.parse(window.localStorage.getItem(key))
    }catch(e){
        return null
    }

}

export const saveState = (key, state) => {
    window.localStorage.setItem(key, JSON.stringify(state))
}


export const isUUID = uuid =>  {
    let s = "" + uuid;

    s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    return s !== null;
}


export const isSerialInt = (str) => {
    if (typeof str != "string") return false // we only process strings!
    if(str === '') return false;
    return !isNaN(+str) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
}

export const getBackgroundColor = event => {
    if(event.user_response_status === false || event.isCancelled) return  '#dc3545';
    if(event.user_response_status === true) return 'green';
    return (parseInt(event.type) === 0 ? '#c3e6cb' : '');
}

export const getEventResponseStatus = (event) => {
    if(event.user_response_status === false) return ` (❌ ${i18n.t('pages.athlete.events.cancelled')})`;
    if(event.user_response_status === true) return ` (✅ ${i18n.t('pages.athlete.events.approved')})`;
    return '';
}