<template>

  <div id="food-preview">

    <b-tabs content-class="mt-1" nav-class="nav-tabs nav-bordered">
      <b-tab :title="$t('food.one')">
        <div class="row">
          <div class="col-md-6">
            <b-form-group id="edit-name" :label="$t('food.name')"  label-for="edit-name">
              <b-input-group>
                <b-form-input id="edit-name" v-model="food.name" type="text" disabled ></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group id="edit-kcal" :label="$t('micro.cal')" label-for="edit-kcal">
              <b-input-group :append="$t('tools.append.kcal')" >
                <b-form-input id="edit-kcal" v-model="food.kcal" type="text" disabled></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <b-form-group id="edit-carbs" :label="$t('micro.carbs')" label-for="edit-carbs">
              <b-input-group :append="$t('tools.append.g')" >
                <b-form-input id="edit-carbs" v-model="food.carbohydrates" type="text" disabled></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group id="edit-proteins" :label="$t('micro.proteins')" label-for="edit-proteins">
              <b-input-group :append="$t('tools.append.g')" >
                <b-form-input id="edit-proteins" v-model="food.proteins" type="text" disabled></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group id="edit-fats" :label="$t('micro.fats')" label-for="edit-fats">
              <b-input-group :append="$t('tools.append.g')" >
                <b-form-input id="edit-fats" v-model="food.fats" type="text" disabled></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-form-group id="edit-quantity" :label="$t('quantity')" label-for="edit-quantity">
              <b-input-group>
                <b-form-input id="edit-quantity" v-model="food.quantity" type="text" disabled></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group id="edit-measure" :label="$t('food.measure')" label-for="edit-measure">
              <label class="select-label">
                <select v-model="food.measurementUnit" class="custom-select" disabled>
                  <option v-for="item in foodMeasures" :key="item.id" :value="item.id">{{ item.name }}</option>
                </select>
              </label>
            </b-form-group>
          </div>
        </div>
        <b-form-group :label="$t('food.chooseType')">
          <div class="row">
            <div class="col-md-4">
              <b-form-checkbox id="isPrwino" v-model="food.foodMealCategory.isBreakfast" name="isBreakfast" disabled>
                {{ $t('meals.breakfast') }}
              </b-form-checkbox>
            </div>
            <div class="col-md-4">
              <b-form-checkbox id="isDekatiano" v-model="food.foodMealCategory.isDekatiano" name="isDekatiano"disabled >
                {{ $t('meals.midBreakfast') }}
              </b-form-checkbox>
            </div>
            <div class="col-md-4">
              <b-form-checkbox id="isMesimeriano" v-model="food.foodMealCategory.isMesimeriano" name="isMesimeriano"disabled >
                {{ $t('meals.lunch') }}
              </b-form-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <b-form-checkbox id="isApogeumatino" v-model="food.foodMealCategory.isApogeumatino" name="isApogeumatino"disabled >
                {{ $t('meals.afterLunch') }}
              </b-form-checkbox>
            </div>
            <div class="col-md-4">
              <b-form-checkbox id="isVradino" v-model="food.foodMealCategory.isVradino" name="isVradino"disabled >
                {{ $t('meals.dinner') }}
              </b-form-checkbox>
            </div>
          </div>
        </b-form-group>
        <b-form-group :label="$t('food.attributes.choose')">
          <div class="row">
            <div class="col-md-4">
              <b-form-checkbox id="isVegan" v-model="food.isVegan" name="isVegan"disabled >
                {{ $t('food.attributes.isVeganQ') }}
              </b-form-checkbox>
            </div>
            <div class="col-md-4">
              <b-form-checkbox id="isFasting" v-model="food.isFasting" name="isFasting"disabled >
                {{ $t('food.attributes.isFastingQ') }}
              </b-form-checkbox>
            </div>
          </div>
        </b-form-group>
        <b-button variant="primary" v-if="food.microNutrients && Object.keys(food.microNutrients).length > 0" v-b-toggle.collapse-3 class="m-1">{{ $t('pages.recipes.label.micro') }}</b-button>
        <b-collapse  v-if="food.microNutrients && Object.keys(food.microNutrients).length > 0" id="collapse-3" class="mb-2">
          <div class="row">
            <div v-for="micronutrient of microNutrientFields" :key="micronutrient" class="col-md-4">
              <label>{{microNutrientTranslation[micronutrient]}}
                <b-form-input v-model="food.microNutrients[micronutrient]" disabled/></label>
            </div>
          </div>
        </b-collapse>
      </b-tab>
      <b-tab :title="$t('cloud.discussion')">

      </b-tab>
    </b-tabs>

    <div class="card-body">

    </div>

  </div>
</template>


<script>

export default{
  props:{
    food: Object
  },
}
</script>
