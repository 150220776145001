export default{
    back :'Επιστροφή στη λίστα',
    printInstructions: 'Εκτύπωση οδηγιών διαιτώμενου',
    dietitian: 'Διατροφολόγος',
    noResults: 'Δεν υπάρχουν ακόμη παθήσεις στη συγκεκριμένη κατηγορία',
    instructions: 'Οδηγίες διαιτώμενου για την πάθηση',
    tabs: {
        0: 'Συστάσεις διατροφής',
        1: 'Οδηγίες προς διαιτώμενο',
        2: 'Προτεινόμενα συμπληρώματα',
        3: 'Διαγνωστικά εργαλεία',
        4: 'Θεωρία πάθησης για μελέτη'
    }
}