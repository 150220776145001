export default {
    add: 'Create new plan',
    systemOne: 'System template',
    system: 'System templates',
    personal: 'Personal templates',
    foodPlan: 'Customizable plan',
    freePlan: 'Free-text plan',
    foodPlans: 'Customizable diet plans',
    freePlans: 'Free-text diet plans',
    empty: 'NO DIET PLANS FOUND',
    emptyText: 'No diet-plans found in this client (or account)',
    types: {
        system: ''
    },
    withDri: '(with DRI %)',
    withoutDri: '(without DRI)',
    micro: 'Micronutrient',
    dri :'DRIs(%)',
    up: 'Ανεκτά Ανώτατα Επίπεδα Πρόσληψης (UP)',
    dayPercentage: '% of day',
    stats: {
        title: 'Diet plan statistics',
        subtitle: 'View the statistics for your diet plans',
        maintainWeight: 'Preserve Weight',
        loseWeight: 'Lose Weight',
        addWeight: 'Add Weight',
        plans: 'plans',
        vegan: 'Vegan',
        fasting: 'Fasting',
        carbohydration: 'Carbohydrations',
        goalPerDp: 'Goals per diet plan',
    },
    microTranslation: {
        saturatedFats: 'Κορεσμένα Λιπαρά (g)',
        monounsaturatedFats: 'Μονοακόρεστα Λιπαρά (g)',
        polyunsaturatedFats: 'Πολυακόρεστα λιπαρά (g)',
        cholesterol: 'Χοληστερόλη (mg)',
        monodisaccharides: 'Μονοδισακχαρίτες (g)',
        polysaccharides: 'Πολυσακχαρίτες (g)',
        dietaryFibers: 'Διαιτητικές Ίνες (g)',
        water: 'Νερό (g)',
        alcohol: 'Αλκοόλη (g)',
        natrium: 'Νάτριο (mg)',
        potassium: 'Κάλιο (mg)',
        calcium: 'Ασβέστιο (mg)',
        magnesium: 'Μαγνήσιο (mg)',
        phosphorus: 'Φώσφορος (mg)',
        iron: 'Σίδηρος (mg)',
        zinc: 'Ψευδάργυρος (mg)',
        retinol: 'Ρετινόλη (mcg)',
        carotene: 'Καροτίνη (mcg)',
        thiamine: 'Θειαμίνη (mg)',
        riboflavin: 'Ριβοφλαβίνη (mg)',
        vitaminB6: 'Βιταμίνη Β6 (mg)',
        vitaminE: 'Βιταμίνη Ε (mg)',
        vitaminC: 'Βιταμίνη C (mg)',
        sugars: 'Σάκχαρα (g)',
        vitaminA: 'Βιταμίνη Α (mcg)',
        caroteneA: 'Καροτίνη Α (mcg)',
        caroteneB: 'Καροτίνη Β (mcg)',
        cryptoxanthin: 'Κρυπτοξανθίνη Β (mcg)',
        lycopene: 'Λυκοπένιο (mcg)',
        lutein_zeaxanthin: 'Λουτεΐνη + ζεαξανθίνη (mcg)',
        niacin: 'Νιασίνη (mg)',
        folicAcid: 'Φολικό οξύ (mcg)',
        folateFood: 'Φολικό, τρόφιμο (mcg)',
        folateDFE: 'Φολικό, DFE (mcg)',
        folateTotal: 'Φολικό, σύνολο (mcg)',
        choline: 'Χολίνη (mg)',
        vitaminB12: 'Βιταμίνη Β12 (mcg)',
        vitaminB12Added: 'Βιταμίνη Β12, πρόσθετη (mcg)',
        vitaminD: 'Βιταμίνη D (D2+D3) (mcg)',
        vitaminK: 'Βιταμίνη Κ (mcg)',
        copper: 'Χαλκός (mg)',
        selenium: 'Σελήνιο (mcg)',
        caffeine: 'Καφεΐνη (mg)',
        theobromine: 'Θεοβρωμίνη (mg)',
    },
}