export default{

    user: 'Χρήστης',
    profile: {
        subtitle: 'Εξερεύνηση του προφίλ του χρήστη',
        listings: {
            title: 'Οι καταχωρήσεις μου',
            subtitle: 'Δείτε και διαγράψτε τις καταχωρήσεις σας από το athlisis cloud.'

        },
        edit: 'Επεξεργασία καταχώρησης',
    },
    ordering: {
        latest: 'Πιο πρόσφατα',
        oldest: 'Πιο παλιά',
        downloads: 'Περισσότερα downloads',
        likes: 'Περισσότερα likes'
    },
    sidebar: {
        categories: 'Κατηγορίες προτύπων',
        home: 'Home',
        plans: 'Διατροφικά πλάνα',
        recipes: 'Συνταγές',
        profile: 'Προφίλ',
        listings: 'Οι καταχωρήσεις μου',
        myProfile: 'Το προφίλ μου',
    },
    home: {
        title: 'Καλωσόρισες στο Athlisis Cloud 👋',
        subtitle: 'Ένα μέρος όπου όλοι οι διατροφολόγοι του athlisis μπορούν να μοιράζονται εύκολα διατροφικά πλάνα, τρόφιμα, συνταγές, πρότυπα γεύματα.',
    },
    plans: {
        title: 'Διατροφικά πλάνα',
        subtitle: 'Εξερευνήστε & μοιραστείτε διατροφικά πλάνα. Χρησιμοποιήστε τις ετικέτες και την αναζήτηση\n' +
            'για ακόμα πιο εξατομικευμένα αποτελέσματα',
    },
    meals: {
        title: 'Coming soon',
        subtitle: 'Εξερευνήστε διατροφικά πλάνα κάθε είδους. Χρησιμοποιήστε τις ετικέτες και την αναζήτηση\n' +
            'για ακόμα πιο εξατομικευμένα αποτελέσματα'
    },
    searchByName: 'Αναζητήστε με βάση το όνομα...',
    tags: 'Αναζήτηση ανά ετικέτα',
    recipes: {
        title: 'Συνταγές',
        subtitle: 'Εξερευνήστε & μοιραστείτε συνταγές. Χρησιμοποιήστε τις ετικέτες και την αναζήτηση\n' +
            '    για ακόμα πιο εξατομικευμένα αποτελέσματα',
    },
    foods: {
        title: 'Τρόφιμα',
        subtitle: 'Εξερευνήστε τρόφιμα που έχουν δημιουργηθεί κατά την προσθήκη συνταγών, γευμάτων'
    },
    popularPlans: 'Δημοφιλή διατροφικά πλάνα',
    noPlans: 'Δεν βρέθηκαν διατροφικά πλάνα.',
    popularRecipes: 'Δημοφιλείς συνταγές',
    noRecipes: 'Δεν βρέθηκαν τρόφιμα.',
    downloads: 'Συνολικές λήψεις',
    likes: 'Συνολικά Likes',
    back: 'Επιστροφή',

    discussion: 'Συζήτηση / σχόλια',
    plan: {
        tabs: {
            0: 'Διατροφικό πλάνο',
            1: 'Ανάλυση πλάνου',
        }
    },
    save: 'Αποθήκευση στα πρότυπά μου',
    saved: 'Αποθηκεύτηκε επιτυχώς στα πρότυπα.',
    comment: 'Το σχόλιό σας',
    noComments: 'Δεν βρέθηκαν σχόλια.',
    commentDisclaimer: 'Απαγορεύονται τα μηνύματα που έχουν ως αποτέλεσμα τη δημιουργία έριδων / κακής ατμόσφαιρας.<br/> Σεβαστείτε όλα τα μέλη ακόμη κι αν διαφωνείτε. Σεβαστείτε όλα τα πρόσωπα φυσικά ή νομικά ακόμη κι αν σας έχουν βλάψει.<br>\n' +
        '            <strong>Μη τήρηση των όρων μπορεί να οδηγήσει σε αποκλεισμό από το Athlisis Cloud.</strong>',
    average: {
        proteins: 'Μέσος όρος πρωτεϊνών  <br>(μόνο ημέρες με τρόφιμα)',
        carbs: 'Μέσος όρος υδατανθράκων  <br>(μόνο ημέρες με τρόφιμα)',
        fats: 'Μέσος όρος λιπών  <br>(μόνο ημέρες με τρόφιμα)',
        kcal: 'Μέσος όρος ενέργειας <br>(μόνο ημέρες με τρόφιμα)'
    },
    dpSuccess: 'Το διατροφικό πλάνο αποθηκεύτηκε επιτυχώς! Μπορείτε να το βρείτε στα "πρότυπα" διατροφικά πλάνα σας!',
    recipeSuccess: 'Η συνταγή αποθηκεύτηκε επιτυχώς!',
    saveTemplateModal: {
        title: 'Θέλετε σίγουρα να κάνετε αποθήκευση;',
        body: '<p>Εάν προχωρήσετε με την αντιγραφή, θα προστεθούν <strong>{count} τρόφιμα</strong> στη λίστα τροφίμων σας από την cloud βιβλιοθήκη (εάν δεν υπάρχουν ήδη).</p>' +
            '<p>Τα τρόφιμα που θα προστεθούν είναι:<br> <strong>{foods}</strong></p>',
        okText: 'Αποθήκευση προτύπου'
    }

}