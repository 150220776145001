export default {
    metaTitle: 'Ημερολόγιο',
    markAsCancelled: 'Ορισμός ως ακυρωμένο',
    revokeCancelled: 'Ορισμός ως μη-ακυρωμένο',
    kinds: {
        title: 'Είδος',
        customer: 'Ραντεβού με πελάτη',
        general: 'Γεγονός / Δραστηριότητα'
    },
    title: 'Τίτλος συμβάντος',
    startTime: 'Ώρα έναρξης',
    duration: 'Διάρκεια',
    endTime: 'Ώρα λήξης',
    minutes: '{time} λεπτά',
    googleMeet: 'Online συνεδρία (Google Meet)',
    addCustomer :'Δημιουργία νέου πελάτη',
    startGoogleMeet: 'Έναρξη συνεδρίας (Google meet)',
    create: 'Προσθήκη ραντεβού',
    update: 'Ενημέρωση ραντεβού',
    delete: 'Διαγραφή ραντεβού',
    errors: {
        missingCustomer: 'Παρακαλώ επιλέξτε έναν πελάτη ή αλλάξτε τον τύπο του συμβάντος.'
    },
    untitled: 'Συμβάν χωρίς τίτλο',
    findTab: '(Κλικ για εύρεση καρτέλας πελάτη)',
    onlyWithGoogle: 'Η λειτουργία είναι διαθέσιμη μόνο εάν έχετε κάνει σύνδεση του google calendar.'
}