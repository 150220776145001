export default{
    'diet-plans': 'Diet plans',
    foods: 'Foods',
    athletes: 'Clients',
    notifications: 'Notifications',
    calendar: 'Calendar',
    meals: 'Meal templates',
    statistics: 'Statistics',
    notes: 'Note templates',
    recipes: 'Recipes',
    company: 'Company details',
    'change-password': 'Change password',
    'todo-list': 'To-do list',
    tags: 'Tags',
    subscription: 'Subscription & Renewal',
    'print-templates': 'Print templates',
    questionnaires: 'Questionnaires',
    user: 'Account',
    'pay-transaction': 'Pay transaction',
    library: 'Scientific Library',
    faq: 'Frequently Asked Questions',
    terms: 'Terms & Conditions',
    admin: 'Admin',
    users: 'User List',
    transactions: 'Transactions',
    reports: 'User reports',
    add: 'Create new client',
    temp: 'Draft',
    coupons: 'Coupon codes / Partners',
    training: 'Training'
}