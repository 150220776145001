import axios from 'axios'
import localforage from "localforage";
import {getSavedState, saveState} from "@utils/util";
import {FOODS_DRI, FOODS_LIST} from "@utils/localForageKeys";


export const state = {
    _foods: null,
    favouriteFoods: getSavedState('food.favouriteFoods') || [],
    lastUpdated: getSavedState('food.lastUpdated'),
    _foodMeasures: getSavedState('food.foodMeasures'),
    _dris: null
};

export const getters = {

    foods(state) {
        return state._foods
    },
    dris(state){
        return state._dris
    },
    favouriteFoods(state){
        return state.favouriteFoods;
    }

};

export const mutations = {

    CACHE_FOODS(state, foods){
        state._foods = foods;
    },

    CACHE_FAVOURITE_FOODS(state, foods){
        if(Array.isArray(foods)){
            state.favouriteFoods = [...foods];
        }else{
            state.favouriteFoods.push(foods);
        }
        saveState('food.favouriteFoods', state.favouriteFoods);
    },
    CACHE_DRIS(state, dris){
        state._dris = dris
    },

    CACHE_FOODS_TIME(state, time){
        state.lastUpdated = time;
    },

    CACHE_FOOD_MEASURES(state, measures){
        state._foodMeasures = measures;
    },

    ADD_NEW_FOOD(state, food){
        state._foods.push(food);
        return saveDatabaseState(FOODS_LIST, state._foods);
    },

    UPDATE_FOOD(state, food){
        let index = state._foods.findIndex(x => x.id === food.id);
        state._foods[index] = food;
        return saveDatabaseState(FOODS_LIST, state._foods);
    },
    DELETE_FOOD(state, id) {
        let index = state._foods.findIndex(x => x.id === id);
        state._foods.splice(index, 1);
        return saveDatabaseState(FOODS_LIST, state._foods);
    }
};

export const actions = {

    async init({ state, commit, dispatch }) {

        dispatch('cacheFavouriteFoods');
        // execute only if user is logged in
        if(localStorage.getItem('auth.token') !== 'null') {
            let foods = await localforage.getItem(FOODS_LIST);

            let dris = await localforage.getItem(FOODS_DRI);

            if (!foods || foods.length === 0) {
                dispatch('fetchCacheFoods')
            } else {
                dispatch('refreshCacheFoods');
            }

            if (!state._foodMeasures || state._foodMeasures.length === 0) {
                dispatch('fetchCacheFoodMeasures')
            } else {
                dispatch('refreshCacheFoodMeasures');
            }

            if(!dris){
                dispatch('fetchCacheDris');
            }else{
                dispatch('refreshCacheDris');
            }
        }
    },


    addNewFood({commit}, payload){
       return commit('ADD_NEW_FOOD', payload);
    },

    updateFood({commit}, payload){
        return commit('UPDATE_FOOD', payload);
    },

    deleteFood({state, commit, dispatch}, id){
        return commit('DELETE_FOOD', id)
    },

    async fetchCacheFoods({ commit }){
        console.warn('Fetching foods...');

        const params = {
            offset:  0,
            limit: 15000,
        };

        return axios.get(process.env.VUE_APP_API + `/food/`, {params: params}).then(result => {
            commit('CACHE_FOODS', result.data.rows);
            commit('CACHE_FOODS_TIME', new Date());
            saveState('food.lastUpdated', state.lastUpdated);
            saveDatabaseState(FOODS_LIST, result.data.rows);

        }).catch( e => {
            console.error('Food fetching has failed!');
            return Promise.reject(e);
        });
    },

    async cacheFavouriteFoods({commit}){
        console.warn('caching favourite foods');
        return axios.get(process.env.VUE_APP_API + `/users/favourite-foods`).then(result => {
            commit('CACHE_FAVOURITE_FOODS', result.data || []);
        }).catch( e => {
            console.error('Favourite foods fetching has failed!');
            return Promise.reject(e);
        });
    },

    async updateFavouriteFoods({state, commit}, foodId){


        if(state.favouriteFoods.includes(foodId)){
            commit('CACHE_FAVOURITE_FOODS', state.favouriteFoods.filter(x => x !== foodId));
        }else{
            commit('CACHE_FAVOURITE_FOODS', foodId)
        }
        return axios.put(process.env.VUE_APP_API + `/users/favourite-foods`, {favouriteFoods: state.favouriteFoods}).then().catch( e => {
            console.error('Update of favourite foods failed');
            return Promise.reject(e);
        });
        //

    },

    async fetchCacheDris({commit}){
        return axios.get(process.env.VUE_APP_API + `/general/dris`).then(result => {
            commit('CACHE_DRIS', result.data);
            saveDatabaseState(FOODS_DRI, result.data);

        }).catch( e => {
            console.error('DRI fetching has failed!');
            return Promise.reject(e);
        });
    },

    fetchCacheFoodMeasures({commit}){
        console.warn('Fetching food measures...');

        return axios.get(process.env.VUE_APP_API + `/food/foodMeasures`).then(result => {

            commit('CACHE_FOOD_MEASURES', result.data);
            saveState('food.foodMeasures', result.data);

        }).catch(e =>{

            console.error('Food measurement fetching has failed!');
        });
    },

    async refreshCacheFoods({commit, dispatch}){

        let foods = await getDatabaseState(FOODS_LIST);
        if(!state.lastUpdated) commit('CACHE_FOODS_TIME', getSavedState('food.lastUpdated'));

        if(!foods || daysBetween(new Date(), new Date(state.lastUpdated)) >= 1){
            return dispatch('fetchCacheFoods');
        }

        if(!state._foods || state._foods.length === 0){
            commit('CACHE_FOODS', foods);
        }
    },

    async refreshCacheDris({commit, dispatch}){
        let dris = await getDatabaseState(FOODS_DRI);
        if(daysBetween(new Date(), new Date(state.lastUpdated)) >= 1){
            return dispatch('fetchCacheDris');
        }
        commit('CACHE_DRIS', dris);
    },



    refreshCacheFoodMeasures({commit, dispatch}){
        if(daysBetween(new Date(), new Date(state.lastUpdated)) >= 1){
            dispatch('fetchCacheFoodMeasures');
        }
        commit('CACHE_FOOD_MEASURES', getSavedState('food.foodMeasures'));
    },

    reset({commit}){
        commit('CACHE_FOODS', null);
        commit('CACHE_FOOD_MEASURES', null);
        commit('CACHE_DRIS', null);
    }
};

function daysBetween(date1, date2) {

    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24;

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(date1 - date2);

    // Convert back to days and return
    return Math.round(differenceMs / ONE_DAY);

}
// // LOCAL FORAGE HELPERS
//
function getDatabaseState(key){
    return localforage.getItem(key);
}
//
function saveDatabaseState(key, state){
    localforage.removeItem(key).then( res => {
        localforage.setItem(key, state).then(res => { return Promise.resolve(res)}).catch(err => {
            console.log(err)
            return Promise.reject(err)});
    });

}
