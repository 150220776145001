export default {
    title: 'Συνταγές',
    missingName: 'Παρακαλώ εισάγετε ένα όνομα για τη συνταγή',
    add: 'Προσθήκη νέας συνταγής',
    empty: 'ΔΕΝ ΒΡΕΘΗΚΑΝ ΣΥΝΤΑΓΕΣ',
    emptyDesc: 'Δεν έχετε αποθηκεύσει ακόμα κάποια συνταγή.',
    name: 'Όνομα συνταγής',
    create: 'Δημιουργία συνταγής',

    photo: 'Φωτογραφία συνταγής',
    recipe: 'Συνταγή',
    ingredients: 'Υλικά',
    execution: 'Οδηγίες εκτέλεσης',
    share: 'Ενεργοποίηση διαμοιρασμού',
    dontForget: 'Μην ξεχάσετε να πατήσετε',
    changeImage: 'Αλλαγή εικόνας',
    deleteImage: 'Διαγραφή εικόνας',
    insertFoodAs: 'Εισαγωγή τροφίμου σε διατροφικά πλάνα',
    per100: 'Ανά 100γρ.',
    perServing: 'Ανά μερίδα',
    howManyServings: 'Για πόσες μερίδες είναι φτιαγμένη η συνταγή',
    serving: 'Μερίδα',
    servings: 'Μερίδες',
    editRemoveIngredients: 'Επεξεργασία / Προσθήκη υλικών',

    copy: {
        title: 'Θέλετε σίγουρα να αντιγράψετε τη συνταγή;',
        body: 'Θα δημιουργηθεί ένα ακριβές αντίγραφο της συνταγής καθώς και ένα νέο τρόφιμο.',
    },
    label: {
        base: 'Βασικά Στοιχεία',
        save: 'Αποθήκευση ετικέτας',
        micro: 'Μικροθρεπτικά',
        microNutrients: 'Μικροθρεπτικά Στοιχεία',
        nutritionValue: 'Διατροφική αξία',
        dietitian: 'Διατροφολόγος',
        modal: {
            title: 'Επιλογή μικροθρεπτικών ετικέτας',
            text: 'Επιλέξτε τα μικροθρεπτικά που θέλετε να φαίνονται στην ετικέτα σας',
            selectAll: 'Επιλογή όλων',
            unselectAll: 'Αποεπιλογή όλων'
        }
    }
}